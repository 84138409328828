import React, { useEffect } from "react";
import AdmissoinNav from "../../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../../components/shared/Footer/AdmissionFooter";
import NigeriaBanksCover from "../../../components/TempBlogCompo/NigeriaBanksCompo/NigeriaBanksCover";
import NigeriaBankContent from "../../../components/TempBlogCompo/NigeriaBanksCompo/NigeriaBankContent";

const NigeriaBanks = () => {
  useEffect(() => {
    document.title =
      "A Guide to UKBA-Approved Banks and Financial Institutions in Nigeria - London School of Excellence";
  }, []);
  return (
    <div>
      <AdmissoinNav />
      <NigeriaBanksCover />
      <NigeriaBankContent />
      <AdmissionFooter />
    </div>
  );
};

export default NigeriaBanks;
