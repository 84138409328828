import React from "react";
import ApplicationForm from "../../components/ApplyNowForAssessmentCompo/ApplicationForm";
import ApplicationFormBanner from "../../components/ApplyNowForAssessmentCompo/ApplicationFormBanner";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import ApplyAssesmentJotForm from "../../components/ApplyNowForAssessmentCompo/ApplyAssesmentJotForm";

const ApplyNowForAssessment = () => {
  return (
    <>
      <AdmissoinNav />
      <ApplicationFormBanner />
      {/* <ApplicationForm /> */}
      <ApplyAssesmentJotForm />
      {/* <AdmissionFooter /> */}
    </>
  );
};

export default ApplyNowForAssessment;
