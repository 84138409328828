import React from "react";
import { Link } from "react-router-dom";

const AllApplication = ({ application }) => {
  const { _id } = application;
  const { firstName, lastName, studentEmail, telephone, semester } =
    application.body;

  return (
    <tr>
      <th>1</th>
      <td>
        <span className="badge badge-ghost badge-sm">{_id}</span>
      </td>
      <td>
        <div className="flex items-center space-x-3">
          <div className="font-bold">
            {firstName} {lastName}
          </div>
        </div>
      </td>
      <td>
        <span className="badge badge-ghost badge-sm">{studentEmail}</span>
      </td>
      <td>
        <span className="badge badge-ghost badge-sm">{telephone}</span>
      </td>
      <td>Received</td>
      <td>{semester}</td>

      <th>
        <Link
          to={`/student-information/${_id}`}
          className="btn btn-secondary btn-xs"
        >
          View Details
        </Link>
      </th>
    </tr>
  );
};

export default AllApplication;
