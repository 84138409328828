import React from "react";
import ServicesWeProvideBanner from "../../components/ServicesWeProvideCompo/ServicesWeProvideBanner";
import ServiceWeProvideDetails from "../../components/ServicesWeProvideCompo/ServiceWeProvideDetails";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";
import EmploymentNav from "../../components/shared/header/EmploymentNav";

const ServicesWeProvide = () => {
  return (
    <div>
      <EmploymentNav />
      <ServicesWeProvideBanner />
      <ServiceWeProvideDetails />
      <EmploymentFooter />
    </div>
  );
};

export default ServicesWeProvide;
