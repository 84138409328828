import React from "react";
import { Link } from "react-router-dom";

const HotelHospitality = () => {
  return (
    <div className="hero bg-base-100 ">
      <div className="hero-content flex-col lg:flex-row-reverse shadow-xl px-20 rounded-xl">
        <img
          src="https://res.cloudinary.com/lsoe/image/upload/v1675790653/images/Civil_Arc_oriry8.png"
          alt=""
          className="max-w-md w-[100%] rounded shadow-1xl"
        />
        <div className="lg:pr-10">
          <h1 className="text-2xl font-bold uppercase text-slate-700 text-start pb-1">
            Hotel & Hospitality
          </h1>
          <ul className="py-10">
            <li className="list-disc list-inside">
              Western, Italian, Chinese food production (Cooking and
              preservation)
            </li>
            <li className="list-disc list-inside">
              Food & Beverage Service (Waiter, Bartender)
            </li>
            <li className="list-disc list-inside">
              Front Office Management (Receptionist call operator)
            </li>
            <li className="list-disc list-inside">
              Room Boy/Laundry Man/Cleaners
            </li>
          </ul>
          <Link to="/find-a-job">
            <button className="btn btn-primary text-white font-bold bg-gradient-to-r from-secondary to-primary">
              Submit Your Application
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HotelHospitality;
