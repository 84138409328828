import React from "react";
import { Link } from "react-router-dom";

const AccommodationWUA = () => {
  return (
    <div className="hero bg-base-100 py-10 drop-shadow-md">
      <div className="hero-content flex-col lg:flex-row">
        <img
          src="https://res.cloudinary.com/lsoe/image/upload/v1678983919/Mesa_de_trabajo_1_iivpai.png"
          className="max-w-lg w-[100%]"
          alt=""
        />
        <div className="lg:pl-10">
          <h1 className="text-2xl font-bold uppercase text-slate-700 text-start pb-1">
            ACCOMMODATIONS
          </h1>
          <p className="text-sm font-normal text-gray-600">
            You will need to arrange accommodation before you arrive in the UK.
            You cannot turn up at your university and move into a room without
            having booked.
          </p>
          <br />
          <p className="text-sm font-normal text-gray-600">
            The capital city of London hardly needs any explanation, but just in
            case you have been too busy studying to notice here are some facts
            and figure.
          </p>
          <br />
          <p className="text-sm font-normal text-gray-600">
            The largest city in the United Kingdom, London is a vibrant,
            multicultural metropolis housing over 8 million residents.
          </p>
          <br />
          <p className="text-sm font-normal text-gray-600">
            It is the home of many of the most recognizable tourist attractions
            in the World such as Buckingham Palace, Houses of Parliament, The
            Tower of London, Westminster Abbey, St Paul’s Cathedral and Tower
            Bridge to name but a few Housing a student population of around
            400,00 London is home to many top-ranking universities – including
            London School of Economics, Imperial College London, UCL, Kings
            College, University of the Arts, Goldsmiths, Westminster and London
            Met along with some of the most significant teaching hospitals in
            the world.
          </p>
          <br />
          <Link to="/student-accommodation">
            <button className="bg-secondary hover:bg-sky-500 text-white font-bold py-2 px-4 border-b-4 border-info-700 rounded">
              Accommodation
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccommodationWUA;
