import React from "react";
import AdmissoinNav from "../../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../../components/shared/Footer/AdmissionFooter";

import IntFormEmbedHub from "../../../components/ApplyNowLeadCompo/IntCompo/IntFormEmbedHub";

const InternatioanlStudent = () => {
  return (
    <>
      <AdmissoinNav />
      <IntFormEmbedHub />
      <AdmissionFooter />
    </>
  );
};

export default InternatioanlStudent;
