import React from "react";
import AdmAboutDetails from "../../components/AdmissionAboutCompo/AdmAboutDetails";
import AdmAdboutBanner from "../../components/AdmissionAboutCompo/AdmAdboutBanner";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";

const AboutUs = () => {
  return (
    <div>
      <AdmissoinNav />
      <AdmAdboutBanner />
      <AdmAboutDetails />
      <AdmissionFooter />
    </div>
  );
};

export default AboutUs;
