import React from "react";
import AdmissoinNav from "../../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../../components/shared/Footer/AdmissionFooter";
import UKEUFormEmbedHub from "../../../components/ApplyNowLeadCompo/UKEUCompo/UKEUFormEmbedHub";

const UKEUStudent = () => {
  return (
    <>
      <AdmissoinNav />
      <UKEUFormEmbedHub />
      <AdmissionFooter />
    </>
  );
};

export default UKEUStudent;
