import React from "react";
import AgentCards from "../../components/DashboardCompo/AgentDsashboardHeader/AgentCards";
import DashboardFooter from "../../components/DashboardCompo/AdminDashboardHeader/DashboardFooter";

const IndexAgentDashboard = () => {
  return (
    <div>
      <AgentCards />
      <DashboardFooter />
    </div>
  );
};

export default IndexAgentDashboard;
