import React from "react";
import AboutLSOEHomeCompo from "../../components/homeCompo/AboutLSOEHomeCompo/AboutLSOEHomeCompo";
import AdmissionHubHomeCompo from "../../components/homeCompo/AdmissionHubHomeCompo/AdmissionHubHomeCompo";
import CareerHubHomeCompo from "../../components/homeCompo/CareerHubHomeCompo/CareerHubHomeCompo";
import EmpoyementHubHomeCompo from "../../components/homeCompo/EmpoyementHubHomeCompo/EmpoyementHubHomeCompo";
import GlobalBanner from "../../components/homeCompo/GlobalBanner/GlobalBanner";
import GlobalNav from "../../components/shared/header/GlobalNav";
import GlobalFooter from "../../components/shared/Footer/GlobalFooter";
import { useEffect } from "react";
import UACButtonGroup from "../../components/UniversityAdmissionCompo/UACButtonGroup";
import Counter from "../../components/homeCompo/Counter/Counter";
import AssessmentWUA from "../../components/WorldUniversityAdmissionCompo/AssessmentWUA";
import StudentVisaAdviceWUA from "../../components/WorldUniversityAdmissionCompo/StudentVisaAdviceWUA";
import FinanceWUA from "../../components/WorldUniversityAdmissionCompo/FinanceWUA";
import AccommodationWUA from "../../components/WorldUniversityAdmissionCompo/AccommodationWUA";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";

const Home = () => {
  useEffect(() => {
    document.title = "LSOE - Home";
  }, []);
  return (
    <div className="bg-base-100">
      <AdmissoinNav />
      <GlobalBanner />
      {/* <HomeSlider />
      <AdmissionHubHomeCompo />
      <EmpoyementHubHomeCompo />
      <CareerHubHomeCompo />
      <AboutLSOEHomeCompo />
      <GlobalFooter /> */}
      <UACButtonGroup />
      <Counter />
      <AssessmentWUA />
      <StudentVisaAdviceWUA />
      <FinanceWUA />
      <AccommodationWUA />
      <AdmissionFooter />
    </div>
  );
};

export default Home;
