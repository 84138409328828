import React from "react";
import { Link } from "react-router-dom";
// import {
//   FaGlobe,
//   FaFacebookSquare,
//   FaLinkedin,
//   FaTwitterSquare,
//   FaInstagramSquare,
//   FaWhatsappSquare,
// } from "react-icons/fa";
// import { MdEmail } from "react-icons/md";
import { ReactSVG } from "react-svg";

import style from "./SocialLinks.module.css";

const SocialLinks = () => {
  return (
    <>
      <div
        className="hero md:h-screen "
        style={{
          backgroundImage: `url("https://res.cloudinary.com/lsoe/image/upload/v1697641776/images/Social_links_bg_dy4xbw.svg")`,
        }}
      >
        <div className={style.socialLinkCard}>
          <div className="card text-primary-content shadow-xl">
            <div className="card-body">
              <div className="max-w-lg text-white">
                <h1 className="text-4xl text-center font-bold pb-5">
                  London School of Excellence
                </h1>
                <p className="text-xl text-center font-bold pb-5">
                  Learn - Teach - Lead
                </p>

                <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                  <div className="text-2xl inline">
                    <Link to="/">
                      <ReactSVG
                        className="w-[100%]"
                        src="https://res.cloudinary.com/lsoe/image/upload/v1697807914/Social%20Icons/5_m2gnab.svg"
                      />
                    </Link>
                  </div>

                  {/* <div className="text-2xl">
              <a
                href="https://www.londonschoolofexcellence.com/"
                target="_blank"
                rel="noreferrer"
                className="font-bold link link-hover"
              >
                <FaGlobe className="inline-block" /> London School of Excellence
              </a>
            </div> */}
                  <div className="text-2xl">
                    <a
                      href="https://www.facebook.com/Londonschoolofexcellence/"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold link link-hover"
                    >
                      <ReactSVG
                        className="w-[100%]"
                        src="https://res.cloudinary.com/lsoe/image/upload/v1697731980/Social%20Icons/icons8-facebook_ucuxb0.svg"
                      />
                    </a>
                  </div>
                  {/* <div className="text-2xl">
              <a
                href="https://www.facebook.com/LondonSourceOfEmployment/"
                target="_blank"
                rel="noreferrer"
                className="font-bold link link-hover"
              >
                <FaFacebookSquare className="inline-block" /> London Source of
                Employment
              </a>
            </div> */}
                  <div className="text-2xl">
                    <a
                      href="https://www.linkedin.com/company/london-school-of-excellence/"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold link link-hover"
                    >
                      <ReactSVG
                        className="w-[100%]"
                        src="https://res.cloudinary.com/lsoe/image/upload/v1697734063/Social%20Icons/icons8-linkedin_eis0js.svg"
                      />
                    </a>
                  </div>
                  {/* <div className="text-2xl">
              <a
                href="https://www.linkedin.com/company/london-source-of-employment/"
                target="_blank"
                rel="noreferrer"
                className="font-bold link link-hover"
              >
                <FaLinkedin className="inline-block" /> London Source of
                Employment
              </a>
            </div> */}
                  <div className="text-2xl">
                    <a
                      href="https://twitter.com/LsoeLtd"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold link link-hover"
                    >
                      <ReactSVG
                        className="w-[100%]"
                        src="https://res.cloudinary.com/lsoe/image/upload/v1697807920/Social%20Icons/4_qo65yy.svg"
                      />
                    </a>
                  </div>
                  <div className="text-2xl">
                    <a
                      href="https://www.instagram.com/lose_team1/"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold link link-hover"
                    >
                      <ReactSVG
                        className="w-[100%]"
                        src="https://res.cloudinary.com/lsoe/image/upload/v1697731980/Social%20Icons/icons8-instagram_druyct.svg"
                      />
                    </a>
                  </div>
                  <div className="text-2xl">
                    <a
                      href="https://wa.me/447890311705"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold link link-hover"
                    >
                      <ReactSVG
                        className="w-[100%]"
                        src="https://res.cloudinary.com/lsoe/image/upload/v1697731980/Social%20Icons/icons8-whatsapp_cubjkh.svg"
                      />
                    </a>
                  </div>
                  <div></div>
                  <div className="text-2xl ">
                    <a
                      href="mailto:info@lsoe.org.uk"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold link link-hover"
                    >
                      <ReactSVG
                        className="w-[100%]"
                        src="https://res.cloudinary.com/lsoe/image/upload/v1697731979/Social%20Icons/icons8-gmail-logo_l4hkix.svg"
                      />
                    </a>
                  </div>
                  <div></div>
                </div>

                <h1 className="text-xl text-center pt-10">
                  Get in touch with LSOE
                </h1>
              </div>
            </div>
            {/* <figure>
              <img
                src="https://res.cloudinary.com/lsoe/image/upload/v1680441465/images/follow-us_tgyf7c.png"
                className="w-[70vh]"
                alt="Album"
              />
            </figure> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialLinks;
