import React, { useEffect } from "react";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import UACBanner from "../../components/UniversityAdmissionCompo/UACBanner";
import UACButtonGroup from "../../components/UniversityAdmissionCompo/UACButtonGroup";
import AssessmentWUA from "../../components/WorldUniversityAdmissionCompo/AssessmentWUA";
import StudentVisaAdviceWUA from "../../components/WorldUniversityAdmissionCompo/StudentVisaAdviceWUA";
import FinanceWUA from "../../components/WorldUniversityAdmissionCompo/FinanceWUA";
import AccommodationWUA from "../../components/WorldUniversityAdmissionCompo/AccommodationWUA";
import Counter from "../../components/homeCompo/Counter/Counter";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";

const UniversityAdmission = () => {
  useEffect(() => {
    document.title = "London School of Excellence - Home";
  }, []);
  return (
    <div className="bg-base-100">
      <AdmissoinNav />
      <UACBanner />
      <UACButtonGroup />
      <Counter />
      <AssessmentWUA />
      <StudentVisaAdviceWUA />
      <FinanceWUA />
      <AccommodationWUA />
      <AdmissionFooter />
    </div>
  );
};

export default UniversityAdmission;
