import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AgentReq from "./AgentReq";

const AgentRequests = () => {
  const [agentReqs, setAgentReqs] = useState([]);

  useEffect(() => {
    fetch("https://lsoe-backend-1392ffa8977e.herokuapp.com/agentapllications")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAgentReqs(data);
      });
  }, []);
  return (
    <div>
      <div className="overflow-x-auto">
        <table className="table w-full">
          {/* <!-- head --> */}
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>Agent Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Country</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {agentReqs.map((agentReq) => (
              <AgentReq agentReq={agentReq} key={agentReq._id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AgentRequests;
