import React, { useEffect } from "react";
import AdmissoinNav from "../../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../../components/shared/Footer/AdmissionFooter";
import SriLankaBanksCover from "../../../components/TempBlogCompo/SriLankaBanksCompo/SriLankaBanksCover";
import SriLankaBankContent from "../../../components/TempBlogCompo/SriLankaBanksCompo/SriLankaBankContent";

const SriLankaBanks = () => {
  useEffect(() => {
    document.title =
      "UKBA Approved Banks & Financial Institutions in Sri Lanka: A Complete Guide - London School of Excellence";
  }, []);
  return (
    <div>
      <AdmissoinNav />
      <SriLankaBanksCover />
      <SriLankaBankContent />
      <AdmissionFooter />
    </div>
  );
};

export default SriLankaBanks;
