import React from "react";

const BDBankContent = () => {
  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-5">
            <p className="text-lg font-normal">
              The UK Border Agency (UKBA) has specific requirements for those
              applying for visas or settlement in the UK. One such requirement
              is the need to demonstrate that the applicant has sufficient funds
              to support themselves during their stay in the UK. To do this, the
              UKBA has compiled a list of approved banks and financial
              institutions in Bangladesh.
              <br />
              <br />
              This blog post will provide an overview of the UKBA approved banks
              and financial institutions in Bangladesh, and highlight the
              importance of using one of these institutions when applying for a
              UK visa or settlement.
              <br />
              <br />
              <strong>
                Here are list of banks and financial institutions in Bangladesh:
              </strong>
              <ul className="list-inside list-decimal">
                <li>AB Bank Limited</li>
                <li>Bank Alfalah Limited</li>
                <li>The City Bank Limited</li>
                <li>Eastern Bank Limited</li>
                <li>
                  International Finance Investment and Commerce Bank Limited
                </li>
                <li>National Credit and Commerce Bank Ltd</li>
                <li>Southeast Bank Ltd</li>
                <li>One Bank Ltd</li>
                <li>Mutual trust Bank Ltd</li>
                <li>BRAC Bank Ltd</li>
                <li>First Security Islami Bank Ltd</li>
                <li>Shahjalal Islami Bank Ltd</li>
                <li>Standard Chartered Bank</li>
                <li>Commercial Bank of Ceylon Ltd</li>
                <li>Citi Bank</li>
                <li>The Hong Kong and Shanghai Banking Corporation Ltd</li>
                <li>Dhaka Bank Limited</li>
                <li>Premier Bank Limited</li>
                <li>Prime Bank Limited</li>
                <li>Pubali Bank Limited</li>
                <li>Standard Bank Limited</li>
                <li>Trust Bank Limited</li>
              </ul>
              <br />
              Using one of these UKBA approved banks or financial institutions
              is crucial when applying for a UK visa or settlement. Failure to
              use an approved institution can result in your visa or settlement
              application being refused, so it's important to choose a reliable
              and trustworthy bank that meets the UKBA's requirements.
              <br />
              <br />
              In conclusion, the UKBA approved banks and financial institutions
              in Bangladesh provide an excellent range of services and are
              well-suited to those looking for a reliable and trustworthy
              institution. By choosing one of these approved banks, you can be
              sure that your visa or settlement application will meet the UKBA's
              requirements and increase your chances of success.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BDBankContent;
