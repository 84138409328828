import React from "react";
import FindATalentBanner from "../../components/FindATalentCompo/FindATalentBanner";
import FindATalentForm from "../../components/FindATalentCompo/FindATalentForm";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";
import EmploymentNav from "../../components/shared/header/EmploymentNav";

const FindATalent = () => {
  return (
    <>
      <EmploymentNav />
      <FindATalentBanner />
      <FindATalentForm />
      <EmploymentFooter />
    </>
  );
};

export default FindATalent;
