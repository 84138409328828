import React from "react";

const DetailsStudentsAcco = () => {
  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-5">
            <p className="text-sm font-normal">
              We Arrange your accommodation before you arrive in the UK
              <br />
              <br />
              You will need to arrange accommodation before you arrive in the
              UK. You cannot turn up at your university and move into a room
              without having booked. You can find information about the
              different accommodation options here
              <br />
              <br />
              The capital city of London hardly needs any explanation, but just
              in case you have been too busy studying to notice here are some
              facts and figure.
              <br />
              <br />
              The largest city in the United Kingdom, London is a vibrant,
              multicultural metropolis housing over 8 million residents.
              <br />
              <br />
              It is the home of many of the most recognisable tourist
              attractions in the World such as Buckingham Palace, Houses of
              Parliament, The Tower of London, Westminster Abbey, St Paul’s
              Cathedral and Tower Bridge to name but a few Housing a student
              population of around 400,00 London is home to many top-ranking
              universities – including London School of Economics, Imperial
              College London, UCL, Kings College, University of the Arts,
              Goldsmiths, Westminster and London Met along with some of the most
              significant teaching hospitals in the world.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsStudentsAcco;
