import React from "react";
import ApplyForAssLocalBanner from "../../components/ApplyForAssLocalCompo/ApplyForAssLocalBanner";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import LocalApplicationForm from "../../components/ApplyForAssLocalCompo/LocalApplicationForm";

const ApplyForAssLocal = () => {
  return (
    <div>
      <AdmissoinNav />
      <ApplyForAssLocalBanner />
      <LocalApplicationForm />
      <AdmissionFooter />
    </div>
  );
};

export default ApplyForAssLocal;
