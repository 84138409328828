import React from "react";

const CollapseSFI = () => {
  return (
    <>
      <div className="my-10 mx-10">
        {/* single-collapse start */}
        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <input type="checkbox" className="mb-0" />
          <div className="collapse-title text-base font-medium">
            Who does not need to meet the financial requirements?
          </div>
          <div className="collapse-content">
            <p className="text-sm font-normal text-gray-600">
              Suppose you are applying for permission to stay in the UK and have
              been living in the UK with permission for 12 months or more on the
              date of your visa application. In that case, you do not need to
              meet the financial requirements listed below or submit financial
              documents.
            </p>
          </div>
        </div>
        {/* single-collapse end */}
        {/* single-collapse start */}
        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <input type="checkbox" className="mb-0" />
          <div className="collapse-title text-base font-medium">
            Who must meet the financial requirements but does not need to submit
            financial documents?
          </div>
          <div className="collapse-content">
            <p className="text-sm font-normal text-gray-600">
              If you are a national of one of the countries mentioned, you must
              meet the financial criteria outlined below. Still, you are not
              required to apply your financial documentation with your Student
              Visa Application. You must apply in the UK or the country where
              you live to benefit from the Differentiation Arrangements as a
              so-called ‘low risk national’.
              <br />
              <br />
              If you depend on the differentiation arrangements, you must have
              the necessary financial documentation on hand and in the correct
              format, as the Home Office may request proof that you meet the
              financial requirements at a later date. If you do not have these
              documents as asked, your application will be denied.
              <br />
              <br />
              All other applicants for a student visa must fulfil the financial
              requirements and submit financial documentation.
            </p>
          </div>
        </div>
        {/* single-collapse end */}
        {/* single-collapse start */}
        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <input type="checkbox" className="mb-0" />
          <div className="collapse-title text-base font-medium">
            The financial requirements
          </div>
          <div className="collapse-content">
            <p className="text-sm font-normal text-gray-600">
              To avoid a visa refusal, the financial documents must meet the
              relevant financial requirements. Before you submit your online
              visa application form, you must have sufficient funds to pay the
              required time. The Student Visa Guidance explains the conditions
              for supporting documentation in detail.
            </p>
          </div>
        </div>
        {/* single-collapse end */}
        {/* single-collapse start */}
        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <input type="checkbox" className="mb-0" />
          <div className="collapse-title text-base font-medium">
            How much money do I need?
          </div>
          <div className="collapse-content">
            <p className="text-sm font-normal text-gray-600">
              You must have sufficient funds to cover the costs of your first
              year of study. If your course is less than a year-long, you must
              have enough money to cover the whole course fee. Your fee amount
              will be confirmed on your Confirmation of acceptance of studies
              (CAS).
              <br />
              <br />
              You will normally need to pay a deposit of £6,000 towards your
              tuition fees in order to be issued with a CAS.
              <br />
              <br />
              For example, if you are applying for a visa to study a 1-year
              Master’s course and:
              <br />
              <br />
              Your fees are £10,000
              <br />
              <br />
              You have paid the £6,000 deposit
              <br />
              <br />
              Your CAS states: Course Fees for the Year: £10,000, Course Fees
              Paid so far: £6,000
              <br />
              <br />
              Your remaining course fee is £4,000
              <br />
              <br />
              You must have £4,000 in your account or your parent’s account for
              your tuition fees in addition to the funds for your living costs.
              <br />
              <br />
              <strong>Living costs</strong>
              <br />
              You must have enough money to support yourself while you are
              studying in the UK. The amount you need to show will be changing
              on 1 December 2020.
              <br />
              <br />
              Applications made on or after 1 December, you will need to show
              £1,334 for each month, or part of a month, of your course for a
              maximum of nine months. The maximum amount you will need for your
              living costs is £12,006.
              <br />
              <br />
              If you want to live with family in the UK or have already paid for
              your accommodation in part or in full, the Home Office has set a
              fixed sum for the living cost requirement.{"\n"} If your course or
              the remainder of your course is less than nine months, you must
              consult your CAS to determine the length of your course. Calculate
              the months or fractions of months before the course ends using the
              CAS start date. If you need to, use a date calculator. Remember
              that you are excluded from the financial condition if you are
              applying in the UK and have been in the UK with approval for a
              period of 12 months or longer.
              <br />
              <br />
              You must show living costs for two months if you are applying for
              the Doctorate Extension Scheme and are not exempt from the
              financial requirements.
              <br />
              <br />
              A worked example for total maintenance:
              <br />
              <br />
              If you are studying on a 1-year Master’s course, your tuition fees
              are £10,000 and you have paid your £6,000 deposit.
              <br />
              <br />
              If you are applying on or after 1 December 2020 you must show
              £4,000 (£10,000 – £6000) for your tuition fees and £12,006
              (£1,3354× 9 months) for your living costs. The total maintenance
              amount you are required to show is £16,006 (£4,000 + £12,006).
            </p>
          </div>
        </div>
        {/* single-collapse end */}
        {/* single-collapse start */}
        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <input type="checkbox" className="mb-0" />
          <div className="collapse-title text-base font-medium">
            The 28-day rule
          </div>
          <div className="collapse-content">
            <p className="text-sm font-normal text-gray-600">
              Any money you use for upkeep (course fees and living expenses)
              must stay in your account or the account of your parent(s) for a
              total of 28 days (finishing on the date of the closing balance).{" "}
              <br />
              <br /> If this money falls below the appropriate sum for even one
              day during the 28-day duration, you will not meet the Home Office
              criteria, and your application will be denied.
            </p>
          </div>
        </div>
        {/* single-collapse end */}
        {/* single-collapse start */}
        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <input type="checkbox" className="mb-0" />
          <div className="collapse-title text-base font-medium">
            Partner and children
          </div>
          <div className="collapse-content">
            <p className="text-sm font-normal text-gray-600">
              Suppose a family member other than your parents or partner (who is
              applying simultaneously as you or already has permission) is
              funding you. In that case, they must move the requisite funds into
              your bank account or use a joint account with your name on it, as
              their bank statements would not be approved.
              <br />
              <br />
              Your partner and children (‘dependants’) may be able to apply to
              come to the UK or stay longer in the UK.
              <br />
              <br />
              <strong>You must be one of the following:</strong>
              <ul>
                <li className="list-disc list-inside">
                  A full-time student on a postgraduate level course (RQF level
                  7 or above) that lasts 9 months or longer
                </li>
                <li className="list-disc list-inside">
                  Your partner and child must each have a certain amount of
                  money available to them. This is in addition to the money you
                  must have to support yourself.
                </li>
                <li className="list-disc list-inside">
                  A full-time student on a postgraduate level course (RQF level
                  7 or above) that lasts 9 months or longer
                </li>
                <li className="list-disc list-inside">
                  A full-time student on a postgraduate level course (RQF level
                  7 or above) that lasts 9 months or longer
                </li>
                <li className="list-disc list-inside">
                  A full-time student on a postgraduate level course (RQF level
                  7 or above) that lasts 9 months or longer
                </li>
                <li className="list-disc list-inside">
                  Your partner and child must each have a certain amount of
                  money available to them. This is in addition to the money you
                  must have to support yourself.
                </li>
              </ul>
              <br />
              <strong>
                How much money they need depends on where you will be studying.
                They must have either:
              </strong>
              <ul>
                <li className="list-disc list-inside">
                  £845 a month (for up to 9 months) for courses in London
                </li>
                <li className="list-disc list-inside">
                  £680 a month (for up to 9 months) for courses outside London
                </li>
                <li className="list-disc list-inside">
                  £475 for the visa ( more info visit :
                  https://www.gov.uk/student-visa/family-members{" "}
                  <a
                    className="link link-accent"
                    href="https://www.gov.uk/student-visa/family-members"
                  >
                    https://www.gov.uk/student-visa/family-members
                  </a>
                  )
                </li>
              </ul>
            </p>
          </div>
        </div>
        {/* single-collapse end */}
        {/* single-collapse start */}
        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <input type="checkbox" className="mb-0" />
          <div className="collapse-title text-base font-medium">
            Financial Evidence
          </div>
          <div className="collapse-content">
            <p className="text-sm font-normal text-gray-600">
              <strong>
                The money you would show can be in any of the following forms:
              </strong>
              <ul>
                <li className="list-decimal list-inside">Your bank account.</li>
                <li className="list-decimal list-inside">
                  Your parent(s)’ bank account.
                </li>
                <li className="list-decimal list-inside">
                  Your partner’s account if they are applying at the same time
                  or they already have permission.
                </li>
                <li className="list-decimal list-inside">
                  A joint account with anyone as long as you are named on the
                  account.
                </li>
                <li className="list-decimal list-inside">
                  A joint account with both your parents’ names.
                </li>
              </ul>
              <br />
              <strong>
                You must include the following documents in support of your
                application if you are dependent on funds from your parent(s)’
                account:
              </strong>
              <ul>
                <li>
                  1. A letter from your parent(s) acknowledging that you are
                  their child and that you have their permission to study in the
                  UK using their funds. Here’s an example of a letter from a
                  parent.
                </li>
                <li>
                  2. Your birth certificate, which contains your name and your
                  parents’ names (with a translation if not in English).For more
                  information on translations, please see our Translations page.
                </li>
                <li>3. Your parent(s)’ financial documents.</li>
              </ul>
            </p>
          </div>
        </div>
        {/* single-collapse end */}
        {/* single-collapse start */}
        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <input type="checkbox" className="mb-0" />
          <div className="collapse-title text-base font-medium">
            Required Documents
          </div>
          <div className="collapse-content">
            <p className="text-sm font-normal text-gray-600">
              <strong>
                The primary documents approved by the Home Office shall include:
              </strong>
              <ul>
                <li>1. Personal bank statements.</li>
                <li>2. Building society passbooks.</li>
                <li>3. Letter from the bank. See an example bank letter.</li>
                <li>4. Letter from an official financial sponsor.</li>
                <li>
                  5. Letter from a regulated financial institution confirming a
                  loan. The loan must be provided by your national government,
                  their state or regional government, a government-sponsored
                  student loan company or part of an academic or educational
                  loan scheme.
                </li>
                <li>6. Certificates of deposit.</li>
              </ul>
              <br />
              The Home Office will only consider proof from certain financial
              institutions in Bangladesh, Cameroon, Ghana, India, Iran,
              Pakistan, the Philippines and Sri Lanka. See Appendix P on the UK
              Government website for a list of appropriate and unacceptable
              banks.
              <br /> <br />
              If your money is not in pounds sterling, you must convert the
              funds into pounds sterling .
              <br /> <br />
              Don’t forget to provide translations if any of your documents are
              not in English. There are specific criteria for translations –
              please ensure that you read the requirements carefully if you need
              any translations. For more detail, see our translation guidelines.
            </p>
          </div>
        </div>
        {/* single-collapse end */}
        {/* single-collapse start */}
        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <input type="checkbox" className="mb-0" />
          <div className="collapse-title text-base font-medium">
            Bank and Financial Statements
          </div>
          <div className="collapse-content">
            <div className="text-sm font-normal text-gray-600">
              <strong>Bank statements</strong>
              Funds may be kept in some sort of a personal bank or a
              construction firm account (including a current account, a deposit,
              a savings account, a pension from which the funds may be withdrawn
              or an investment account) provided that the account enables the
              funds to be accessed instantly.
              <br /> <br />
              Funds kept in other accounts or financial instruments, such as
              shares, bonds, credit cards, pensions from which the funds cannot
              be withdrawn immediately, irrespective of the time of
              notification, would not be recognized as proof of the funds.
              <br /> <br />
              Bank or building society statements must cover a 28-day period
              ending no more than 31 days before the date of your application.
              <strong>
                The Home Office would normally expect a banking or building
                society record to:
              </strong>
              <ul>
                <li>1. Be on official stationery or an electronic record.</li>
                <li>2. Be printed or electronic (not handwritten).</li>
                <li>3. Include the account holder(s) names.</li>
                <li>4. Include the account number.</li>
                <li>5. Include the date of the statement</li>
                <li>
                  6. Include information about the bank, such as contact details
                  or a branch code.
                </li>
              </ul>
              A statement will show transactions and amounts held over time
              (although this is not the norm in all countries) and would not
              apply to a letter.
              <br /> <br />
              A letter would in addition to the above criteria confirm the
              balance and length of time funds were held rather than providing a
              record of transactions and
              <br />
              <ul>
                <li>1. Be on headed paper.</li>
                <li>
                  2. Be signed by an official from the financial institution.
                </li>
              </ul>
              <br />
              <strong>Certificate of deposit</strong>
              This is a certificate issued by a bank to confirm that you or your
              parent has deposited a specific amount of money. Certificates of
              the deposit will normally be accepted if the certificate shows:
              <br />
              <ul>
                <li>
                  1. That your certificate of deposit was given within 31 days
                  of your application date (the date you pay the online visa
                  application fee) and
                </li>
                <li>
                  2. That at least 28 days have passed since the deposit was
                  made and the certificate was issued
                </li>
              </ul>
              <br />
              Certificates of deposit should also include the following details:
              <br />
              <ul>
                <li>1. Your name or your parent’s name</li>
                <li>2. The amount of money available</li>
                <li>3. The financial institution’s name and logo</li>
                <li>4. The account number</li>
              </ul>
            </div>
          </div>
        </div>
        {/* single-collapse end */}
      </div>
    </>
  );
};

export default CollapseSFI;
