import React from "react";
import { NavLink } from "react-router-dom";

const CareerHubCompoBanner = () => {
  return (
    <div
      className="hero h-[70vh] bg-base-100"
      style={{
        backgroundImage: `url("https://res.cloudinary.com/lsoe/image/upload/v1678912515/BG1_roq4o4.jpg")`,
      }}
    >
      <div className="hero-content flex-col lg:flex-row-reverse">
        <img
          src="https://res.cloudinary.com/lsoe/image/upload/v1674833761/images/booking_q1bfyu.png"
          className="max-sm"
          alt="University Admission"
        />
        <div>
          <h1 className="text-3xl font-bold">
            Book an Appointment with LSOE Career Advisor
          </h1>
          <p className="py-6 font-normal">
            Book an appointment with a career advisor to discuss university
            admissions and employment opportunities. They can provide guidance
            on how to improve your chances of getting accepted into your desired
            university and help you navigate the job market. They can also
            assist you with building a strong resume and developing a career
            plan. Contact them today to schedule a meeting and take the first
            step towards achieving your academic and professional goals. They
            will work with you to identify your strengths, interests and values,
            and to explore and plan for your future career goals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CareerHubCompoBanner;
