import React from "react";
import { Link } from "react-router-dom";

const StudentVisaAdviceWUA = () => {
  return (
    <div className="hero bg-base-100 py-10">
      <div className="hero-content flex-col lg:flex-row">
        <img
          src="https://res.cloudinary.com/lsoe/image/upload/v1678985581/Visa_Advice_mdv28h.png"
          className="max-w-lg w-[100%]"
          alt=""
        />
        <div className="lg:pl-10">
          <h1 className="text-2xl font-bold uppercase text-slate-700 text-start pb-1">
            STUDENT VISA ADVICE
          </h1>
          <p className="text-sm font-normal text-gray-600">
            Getting your visa at the right time is as important as getting your
            admission. Not being able to attend your classes or enrollment dates
            can set you back.
          </p>
          <br />
          <p className="text-sm font-normal text-gray-600">
            LSOE’s visa experts can help you get your visa in time and prepare
            you for your journey.
          </p>
          <br />
          <p className="text-sm font-normal text-gray-600">
            You can use our WhatsApp contact at the bottom of this page to get
            advice on your student visa.
          </p>
          <br />
          <Link to="/student-visa-advice">
            <button className="bg-secondary hover:bg-sky-500 text-white font-bold py-2 px-4 border-b-4 border-info-700 rounded">
              STUDENT VISA ADVICE
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StudentVisaAdviceWUA;
