import React from "react";
import AdmAboutDetails from "../../components/AdmissionAboutCompo/AdmAboutDetails";
import AdmAdboutBanner from "../../components/AdmissionAboutCompo/AdmAdboutBanner";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";

const AboutAdmissionHub = () => {
  return (
    <>
      <AdmissoinNav />
      <AdmAdboutBanner />
      <AdmAboutDetails />
      <AdmissionFooter />
    </>
  );
};

export default AboutAdmissionHub;
