import React from "react";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import ICON from "./../../../../src/assets/icon.png";
import auth from "../../../firebase.init";

const AdminDashboardHeader = () => {
  const [user, loading, error] = useAuthState(auth);
  const logout = () => {
    signOut(auth);
  };

  return (
    <>
      <header className="sticky top-0 z-50">
        <div className="navbar bg-base-100 border-b-4 border-indigo-500">
          <div className="navbar-start">
            <div className="dropdown">
              <label
                tabIndex="1"
                htmlFor="admin-dashboard-sidebar"
                className="btn btn-ghost lg:hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </label>
            </div>
            <Link to="/" className="w-20 lg:ml-10">
              <img src={ICON} alt="Logo" />
            </Link>
          </div>
          <div className="navbar-end">
            {user ? (
              <>
                <p className="mr-5">Hi, {user.displayName}</p>
                <div className="dropdown dropdown-end">
                  <label tabIndex={0}>
                    <div className="avatar placeholder cursor-pointer">
                      <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
                        <span>{<img src={user.photoURL} alt="avatar" />}</span>
                      </div>
                    </div>
                  </label>
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <li>
                      <a>Profile</a>
                    </li>
                    <li>
                      <a>Setting</a>
                    </li>
                    <li>
                      <button className="btn btn-ghost" onClick={logout}>
                        Sgin Out
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <Link to="/login" className="btn">
                Get started
              </Link>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default AdminDashboardHeader;
