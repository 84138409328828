import React from "react";
import { Link } from "react-router-dom";

const UACButtonGroup = () => {
  return (
    <div className="bg-base-100 py-16 container mx-auto">
      <p className="text-center text-secondary uppercase font-bold py-5 text-2xl">
        Explore LSOE Admission HUB
      </p>
      <div className="grid grid-cols-1 md:grid-cols-5 place-items-center ">
        {/* <div>
          <Link to="/about-admission-hub">
            <button className="btn btn-outline btn-secondary m-3 px-5">
              About London School of Excellence
            </button>
          </Link>
        </div> */}
        <div>
          <Link to="/apply-now-for-assessment">
            <button className="btn btn-outline btn-secondary m-3 px-5">
              Apply now for assessment
            </button>
          </Link>
        </div>
        <div>
          <Link to="/student-visa-advice">
            <button className="btn btn-outline btn-secondary m-3 px-5">
              Student Visa Advice
            </button>
          </Link>
        </div>
        <div>
          <Link to="/student-financial-information">
            <button className="btn btn-outline btn-secondary m-3 px-5">
              Student financial information
            </button>
          </Link>
        </div>
        <div>
          <Link to="/student-accommodation">
            <button className="btn btn-outline btn-secondary m-3  px-5">
              Student accommodation
            </button>
          </Link>
        </div>
        <div>
          <Link to="/become-an-agent">
            <button className="btn btn-outline btn-secondary m-3 px-5">
              LSOE Agents Hub
            </button>
          </Link>
        </div>
      </div>
      {/* <div className="grid grid-cols-1 md:grid-cols-6 place-items-center items-center">
        <div>
          <Link to="/become-an-agent">
            <button className="btn btn-outline btn-secondary m-3 px-5">
              LSOE Agents Hub
            </button>
          </Link>
        </div>
        <div>
          <Link to="/about-admission-hub">
            <button className="btn btn-outline btn-secondary m-3 px-5">
              About London School of Excellence
            </button>
          </Link>
        </div>

        <div>
          <Link to="/contact-london-school-of-excellence">
            <button className="btn btn-outline btn-secondary m-3  px-5">
              Contact London School of Excellence
            </button>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default UACButtonGroup;
