import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { Controller, useForm } from "react-hook-form";
import Loading from "../shared/Loading";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CourseList from "../shared/FormField/CourseList";
import UniversityList from "../shared/FormField/UniversityList";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const LocalApplicationForm = () => {
  const [user, loading] = useAuthState(auth);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    (async () => {
      await sleep(50);
      setValue("course", [], { shouldValidate: true });
      reset({
        course: [],
      });
    })();
  }, [reset, setValue]);

  if (loading) {
    return <Loading />;
  }

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("gender", data.gender);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("dateOfBirth", data.dateOfBirth);
    formData.append("nationality", data.nationality);
    formData.append("streetAdress", data.streetAdress);
    formData.append("city", data.city);
    formData.append("region", data.region);
    formData.append("postalCode", data.postalCode);
    formData.append("telephone", data.telephone);
    formData.append("studentEmail", data.studentEmail);
    formData.append("fileEntryDate", data.fileEntryDate);
    formData.append("euShareCode", data.euShareCode);
    formData.append("intake", data.intake);
    formData.append("grade", data.grade);
    formData.append("course", data.course);
    formData.append("university", data.university);
    formData.append("backgroundInfo", data.backgroundInfo);
    formData.append("cv", data.cv[0]);
    formData.append("passport", data.passport[0]);
    formData.append("ieltsDoc", data.ieltsDoc[0]);
    formData.append("academicDoc1", data.academicDoc1[0]);
    formData.append("otherDoc1", data.otherDoc1[0]);
    formData.append("logedInUserName", data.logedInUserName);
    formData.append("email", data.email);

    try {
      await fetch(
        "https://lsoe-backend-1392ffa8977e.herokuapp.com/managelocalapllications",
        {
          method: "POST",
          body: formData,
        }
      );

      toast.success("Wow! Your Application Submitted Successfully!");
      console.log("File and data uploaded successfully.");
    } catch (error) {
      console.error("Error uploading file and data:", error);
      toast.error("Error uploading file and data");
    }
  };
  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="overflow-hidden">
                <p className="font-bold text-xl text-black text-center mt-5">
                  Personal Information
                </p>
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("title")}
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      >
                        <option disabled defaultValue>
                          Title
                        </option>
                        <option>Mr.</option>
                        <option>Ms.</option>
                        <option>Mrs.</option>
                        <option>Miss.</option>
                        <option>Dr.</option>
                        <option>Prof.</option>
                      </select>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("gender")}
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      >
                        <option disabled defaultValue>
                          Gender
                        </option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="First Name (Required)"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("firstName", {
                          required: {
                            value: true,
                            message: "First Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.firstName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.firstName.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="Last Name (Required)"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("lastName", {
                          required: {
                            value: true,
                            message: "Last Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.lastName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.lastName.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <Controller
                        control={control}
                        id="date"
                        type="date"
                        name="date-input"
                        {...register("dateOfBirth", {
                          required: "Date of Birth is required",
                        })}
                        render={({ field }) => (
                          <DatePicker
                            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                            placeholderText="Date of Birth"
                            showIcon
                            isClearable
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={50}
                            maxDate={new Date()}
                            dropdownMode="select"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                          />
                        )}
                      />
                      <label className="label">
                        {errors.dateOfBirth?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.dateOfBirth.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("nationality", {
                          required: {
                            value: true,
                            message: "Nationality is Required",
                          },
                        })}
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      >
                        <label className="label">
                          {errors.nationality?.type === "required" && (
                            <span className="label-text-alt text-red-500">
                              {errors.nationality.message}
                            </span>
                          )}
                        </label>
                        <option disabled defaultValue>
                          Nationality
                        </option>
                        <option>United Kingdom</option>
                        <option>United States</option>
                        <option>Bangladesh</option>
                        <option>Rumania</option>
                        <option>Italy</option>
                        <option>Sri Lanka</option>
                        <option>Ghana</option>
                        <option>Nigeria</option>
                        <option>Tunisia</option>
                        <option>Guinea</option>
                        <option>Other</option>
                      </select>
                    </div>
                    {/* single field */}

                    <div className="col-span-6">
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        placeholder="Street Address"
                        autoComplete="street-address"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("streetAdress", {
                          required: {
                            value: true,
                            message: "Street Address is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.streetAdress?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.streetAdress.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <input
                        {...register("city")}
                        placeholder="City"
                        type="text"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      />
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <input
                        type="text"
                        placeholder="State / Province"
                        {...register("region")}
                        autoComplete="address-level"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      />
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <input
                        {...register("postalCode")}
                        type="text"
                        name="postal-code"
                        placeholder="ZIP / Postal code"
                        id="postal-code"
                        autoComplete="postal-code"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      />
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="number"
                        placeholder="Phone Number (Required)"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("telephone", {
                          required: {
                            value: true,
                            message: "Telephone Number is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.telephone?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.telephone.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 w-full">
                      <input
                        type="email"
                        placeholder="Student Email (Required)"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("studentEmail", {
                          required: {
                            value: true,
                            message: "Student Email is Required",
                          },
                          pattern: {
                            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                            message: "Provide a valid Email",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.email?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.email.message}
                          </span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span className="label-text-alt text-red-500">
                            {errors.email.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="File Entry Date"
                        defaultValue={new Date()}
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("fileEntryDate")}
                      />
                    </div>

                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="EU SHARE CODE"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("euShareCode", {
                          required: {
                            value: true,
                            message: "EU SHARE CODE is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.euShareCode?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.euShareCode.message}
                          </span>
                        )}
                      </label>
                    </div>

                    {/* single field */}

                    <p className="font-bold text-xl text-black text-center col-span-6 my-3">
                      Courses Selection
                    </p>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="Intake"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("intake")}
                      />
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("grade")}
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      >
                        <option disabled defaultValue>
                          What grade are you applying for?
                        </option>
                        <option>Undergraduate</option>
                        <option>Postgraduate</option>
                        <option>Diploma</option>
                        <option>PHD</option>
                        <option>DBA</option>
                      </select>
                    </div>
                    {/* single field */}

                    <div className="col-span-6">
                      {/* course selection from react select start  */}
                      <CourseList
                        name={"course"}
                        control={control}
                        values={[
                          "BA (Hons) Accounting and Finance",
                          "BA (Hons) Advertising, Marketing Communications and Public Relations",
                          "BSc (Hons) Airline, Airport and Aviation Management",
                          "BA (Hons) Architecture",
                          "BSc (Hons) Banking and Finance",
                          "BA (Hons) Business Management",
                          "BA (Hons) Business Management and Human Resource Management",
                          "BA (Hons) Business Management and Marketing",
                          "BSc (Hons) Criminology",
                          "BA (Hons) Criminology and International Security",
                          "BSc (Hons) Criminology and Policing",
                          "BSc (Hons) Criminology and Psychology",
                          "BSc (Hons) Criminology and Sociology",
                          "BSc (Hons) Criminology and Youth Studies",
                          "BA (Hons) Digital Media",
                          "BA (Hons) Diplomacy and International Relations",
                          "BA (Hons) Early Childhood Studies",
                          "BSc (Hons) Economics and Finance",
                          "BA (Hons) Education",
                          "BA (Hons) Events Management",
                          "BA (Hons) Events Management and Marketing",
                          "BA (Hons) Fashion Marketing and Business Management",
                          "BA (Hons) Fashion Textiles",
                          "BA (Hons) Film and Television Production",
                          "BA (Hons) Film and Television Studies",
                          "BA (Hons) Graphic Design",
                          "BSc (Hons) Health and Social Care",
                          "BA (Hons) Illustration and Animation",
                          "BA (Hons) Interior Architecture and Design",
                          "BA (Hons) Interior Design",
                          "BSc (Hons) International Business Management",
                          "BA (Hons) International Relations",
                          "BA (Hons) International Relations and Politics",
                          "(Hons) LLB (Criminal Law)",
                          "(Hons) LLB Law",
                          "BA (Hons) Law",
                          "BA (Hons) Marketing",
                          "BSc (Hons) Media and Communications",
                          "BA (Hons) Music Business",
                          "BA (Hons) Politics",
                          "BSc (Hons) Psychology",
                          "BSc (Hons) Sociology",
                          "BA (Hons) Textiles",
                          "BA (Hons) Tourism and Travel Management",
                          "MBA",
                          "MBA with placement year",
                          "MBA (Top-up)",
                        ]}
                      />
                      {/* course selection from react select end  */}
                    </div>
                    {/* single field */}

                    <div className="col-span-6">
                      {/* course selection from react select start  */}
                      <UniversityList
                        name={"university"}
                        control={control}
                        values={[
                          "Solent University",
                          "University of Roehampton London",
                          "Ulster University",
                          "Northumbria University - London",
                          "London Metropolitan University",
                        ]}
                      />
                      {/* course selection from react select end  */}
                    </div>
                    {/* single field */}

                    {/* single field */}

                    <div className="col-span-6">
                      <textarea
                        type="text"
                        name="bacakground info"
                        placeholder="Background Info"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("backgroundInfo")}
                      ></textarea>
                    </div>

                    {/* single field */}

                    <p className="font-bold text-xl text-black text-center col-span-6 mt-3">
                      Additional Documents
                    </p>

                    <p className="font-semibold text-md text-red-600 text-center col-span-6">
                      (Important: Maximum File upload size 10 MB. Please check
                      your file size before upload)
                    </p>
                    {/* single field */}

                    <div className="col-span-6 ">
                      <label
                        htmlFor="formFile"
                        className="mb-2 inline-block text-neutral-700"
                      >
                        Curriculum Vitae
                      </label>
                      <input
                        className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                        type="file"
                        id="formFile"
                        {...register("cv")}
                      />
                    </div>
                    {/* single field */}

                    <div className="col-span-6 ">
                      <label
                        htmlFor="formFile"
                        className="mb-2 inline-block text-neutral-700"
                      >
                        Official Photo ID or Passport
                      </label>
                      <input
                        className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                        type="file"
                        id="formFile"
                        {...register("passport")}
                      />
                    </div>
                    {/* single field */}
                    <div className="col-span-6 ">
                      <label
                        htmlFor="formFile"
                        className="mb-2 inline-block text-neutral-700"
                      >
                        IELTS Documents
                      </label>

                      <input
                        className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                        type="file"
                        id="formFile"
                        {...register("ieltsDoc")}
                      />
                    </div>

                    {/* single field */}

                    <div className="col-span-6">
                      <label
                        htmlFor="formFileMultiple"
                        className="mb-2 inline-block text-neutral-700"
                      >
                        Upload All Academic Documents
                      </label>
                      <input
                        className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none "
                        type="file"
                        id="formFileMultiple"
                        multiple
                        {...register("academicDoc1")}
                      />
                    </div>

                    {/* single field */}

                    <div className="col-span-6">
                      <label
                        htmlFor="formFileMultiple"
                        className="mb-2 inline-block text-neutral-700"
                      >
                        Upload All Other Documents
                      </label>
                      <input
                        className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                        type="file"
                        id="formFileMultiple"
                        multiple
                        {...register("otherDoc1")}
                      />
                    </div>

                    {/* single field */}

                    {/* End single input group */}

                    <p className="font-bold text-xl text-accent text-center col-span-6 my-3">
                      Loged in Agent or Student Details
                    </p>

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <label className="block text-sm font-medium text-gray-700">
                        User Name
                      </label>
                      <input
                        type="text"
                        defaultValue={user?.displayName}
                        placeholder="Name"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("logedInUserName")}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <label className="block text-sm font-medium text-gray-700">
                        User Email
                      </label>
                      <input
                        type="email"
                        defaultValue={user.email}
                        placeholder="Email"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        {...register("email")}
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className=" w-full inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Send Application
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalApplicationForm;
