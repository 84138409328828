import React from "react";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import CollapseSFI from "../../components/StudentFinancialInformationCompo/CollapseSFI";
import CoverSFI from "../../components/StudentFinancialInformationCompo/CoverSFI";
import BlockquoteSFI from "../../components/StudentFinancialInformationCompo/BlockquoteSFI";
import UKVIBankBlogLinksSFI from "../../components/StudentFinancialInformationCompo/UKVIBankBlogLinksSFI";

const StudentFinancialInformation = () => {
  return (
    <div>
      <AdmissoinNav />
      <CoverSFI />
      <CollapseSFI />
      <UKVIBankBlogLinksSFI />
      <BlockquoteSFI />
      <AdmissionFooter />
    </div>
  );
};

export default StudentFinancialInformation;
