import React from "react";
import { ReactSVG } from "react-svg";

const GlobalBanner = () => {
  return (
    <div
      className="hero py-10 h-[70vh] drop-shadow-sm bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url("https://res.cloudinary.com/lsoe/image/upload/v1678472241/images/Global_Bg_nj1uin.jpg")`,
      }}
    >
      <div className="hero-content content-around flex-col lg:flex-row-reverse container">
        <ReactSVG
          className="w-[20rem] sm:w-[30rem] md:w-[40rem]"
          src="https://res.cloudinary.com/lsoe/image/upload/v1686048892/Ten_Years_bpvdks.svg"
        />

        <div>
          <img
            className="w-[80%]"
            src="https://res.cloudinary.com/lsoe/image/upload/v1678473585/images/my-time_ptrmu3.png"
            alt="My Time is Now"
          />
          <h2 className="text-2xl text-gray-800 text-left pt-5 ml-10 font-bold">
            Lead - Teach - Learn
          </h2>
        </div>
      </div>
    </div>
  );
};

export default GlobalBanner;
