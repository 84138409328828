import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import auth from "../../firebase.init";
import Loading from "../shared/Loading";
import "react-datepicker/dist/react-datepicker.css";

const AgentRequestForm = () => {
  const [user, loading] = useAuthState(auth);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm();

  if (loading) {
    return <Loading />;
  }

  const onSubmit = (data) => {
    fetch("https://lsoe-backend-1392ffa8977e.herokuapp.com/agentapllications", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        toast.success("Wow! Your Application Submitted Successfully!");
      });
  };

  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="overflow-hidden">
                <p className="font-bold text-xl text-black text-center mt-5">
                  COMPANY DETAILS
                </p>
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    {/* single field */}
                    <div className="col-span-6">
                      <input
                        type="text"
                        name="businessName"
                        id="businessName"
                        placeholder="Business Name (Required)"
                        autoComplete="businessName"
                        className="input input-bordered input-sm w-full"
                        {...register("businessName", {
                          required: {
                            value: true,
                            message: "Business Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.businessName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.businessName.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}

                    <div className="col-span-6">
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        placeholder="Street Address"
                        autoComplete="street-address"
                        className="input input-bordered input-sm w-full"
                        {...register("streetAdress", {
                          required: {
                            value: true,
                            message: "Street Address is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.streetAdress?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.streetAdress.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("city")}
                        placeholder="City"
                        type="text"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="text"
                        placeholder="State / Province"
                        {...register("region")}
                        autoComplete="address-level"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("postalCode")}
                        type="text"
                        name="postal-code"
                        placeholder="ZIP / Postal code"
                        id="postal-code"
                        autoComplete="postal-code"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("country")}
                        type="text"
                        name="country"
                        placeholder="Country"
                        id="country"
                        autoComplete="country"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("businessPhoneNumber", {
                          required: {
                            value: true,
                            message: "Business Phone Number is Required",
                          },
                        })}
                        type="text"
                        name="businessPhoneNumber"
                        placeholder="Business Phone Number (Required)"
                        id="businessPhoneNumber"
                        className="input input-bordered input-sm w-full"
                      />
                      <label className="label">
                        {errors.businessPhoneNumber?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.businessPhoneNumber.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("websiteOrSocialMediaPage")}
                        type="text"
                        name="websiteOrSocialMediaPage"
                        placeholder="Website/Social Media Page"
                        id="websiteOrSocialMediaPage"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("businessEmail", {
                          required: {
                            value: true,
                            message: "Business Email is Required",
                          },
                        })}
                        type="text"
                        name="businessEmail"
                        placeholder="Business Email (Required)"
                        id="businessEmail"
                        className="input input-bordered input-sm w-full"
                      />
                      <label className="label">
                        {errors.businessEmail?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.businessEmail.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("linkedinPage")}
                        type="text"
                        name="linkedinPage"
                        placeholder="LinkedIn Page (Optional)"
                        id="linkedinPage"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>
                    {/* single field */}

                    <p className="font-bold text-xl text-black text-center col-span-6 my-3">
                      PERSON CONTACT DETAILS
                    </p>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("title")}
                        className="input input-bordered input-sm w-full"
                      >
                        <option disabled selected>
                          Title
                        </option>
                        <option>Mr.</option>
                        <option>Ms.</option>
                        <option>Mrs.</option>
                        <option>Miss.</option>
                        <option>Dr.</option>
                        <option>Prof.</option>
                      </select>
                    </div>

                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("position")}
                        type="text"
                        name="position"
                        placeholder="Position/Job Title"
                        id="position"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="First Name"
                        className="input input-bordered input-sm w-full"
                        {...register("firstName", {
                          required: {
                            value: true,
                            message: "First Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.firstName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.firstName.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="input input-bordered input-sm w-full"
                        {...register("lastName", {
                          required: {
                            value: true,
                            message: "Last Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.lastName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.lastName.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="number"
                        placeholder="Phone Number"
                        className="input input-bordered input-sm w-full"
                        {...register("telephone", {
                          required: {
                            value: true,
                            message: "Phone Number is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.telephone?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.telephone.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 w-full">
                      <input
                        type="email"
                        placeholder="Your Email (Required)"
                        className="input input-bordered input-sm w-full"
                        {...register("personEmail", {
                          required: {
                            value: true,
                            message: "Email is Required",
                          },
                          pattern: {
                            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                            message: "Provide a valid Email",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.email?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.email.message}
                          </span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span className="label-text-alt text-red-500">
                            {errors.email.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("functionRole")}
                        className="input input-bordered input-sm w-full"
                      >
                        <option disabled selected>
                          Function/Role
                        </option>
                        <option>Admin Support</option>
                        <option>Admissions</option>
                        <option>Counsellor</option>
                        <option>Finance</option>
                        <option>Marketing</option>
                        <option>Sales</option>
                      </select>
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 w-full">
                      <input
                        type="text"
                        placeholder="Linkedin URL"
                        name="employeeLInkedin"
                        className="input input-bordered input-sm w-full"
                        {...register("employeeLInkedin")}
                      />
                    </div>
                    {/* single field */}

                    {/* <p className="font-bold text-xl text-black text-center col-span-6 my-3">
                      BANK DETAILS
                    </p>

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="Bank Name"
                        className="input input-bordered input-sm w-full"
                        {...register("bankName", {
                          required: {
                            value: true,
                            message: "First Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.firstName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.firstName.message}
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="Branch Name"
                        className="input input-bordered input-sm w-full"
                        {...register("branchName", {
                          required: {
                            value: true,
                            message: "Last Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.lastName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.lastName.message}
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="col-span-6">
                      <input
                        type="text"
                        name="bank-address"
                        id="bank-address"
                        placeholder="Bank Address"
                        autoComplete="street-address"
                        className="input input-bordered input-sm w-full"
                        {...register("bankAddress", {
                          required: {
                            value: true,
                            message: "Email is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.streetAdress?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.streetAdress.message}
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("bankCity")}
                        placeholder="City"
                        type="text"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="text"
                        placeholder="State / Province"
                        {...register("bankRegion")}
                        autoComplete="address-level"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("bankPostalCode")}
                        type="text"
                        name="postal-code"
                        placeholder="ZIP / Postal code"
                        id="postal-code"
                        autoComplete="postal-code"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <input
                        {...register("bankCountry")}
                        type="text"
                        name="country"
                        placeholder="Country"
                        id="country"
                        autoComplete="country"
                        className="input input-bordered input-sm w-full"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="Account Name"
                        className="input input-bordered input-sm w-full"
                        {...register("accountName", {
                          required: {
                            value: true,
                            message: "First Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.firstName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.firstName.message}
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="Account Number"
                        className="input input-bordered input-sm w-full"
                        {...register("accountNumber", {
                          required: {
                            value: true,
                            message: "Last Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.lastName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.lastName.message}
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="SWIFT/BIC Code"
                        className="input input-bordered input-sm w-full"
                        {...register("swiftBicCode", {
                          required: {
                            value: true,
                            message: "First Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.firstName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.firstName.message}
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="Sort Code/BSB Number"
                        className="input input-bordered input-sm w-full"
                        {...register("sortCodeBSBNumber", {
                          required: {
                            value: true,
                            message: "Last Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.lastName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.lastName.message}
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="IBAN"
                        className="input input-bordered input-sm w-full"
                        {...register("iban", {
                          required: {
                            value: true,
                            message: "First Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.firstName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.firstName.message}
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="text"
                        placeholder="Intermediary Swift Code"
                        className="input input-bordered input-sm w-full"
                        {...register("intermediarySwiftCode", {
                          required: {
                            value: true,
                            message: "Last Name is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.lastName?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.lastName.message}
                          </span>
                        )}
                      </label>
                    </div> */}

                    <p className="font-bold text-xl text-black text-center col-span-6 my-3">
                      COMPANY OVERVIEW
                    </p>

                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="number"
                        placeholder="What year did your business start operating?"
                        className="input input-bordered input-sm w-full"
                        {...register("businessStartingYear", {
                          required: {
                            value: true,
                            message: "Business Starting Year is Required",
                          },
                        })}
                      />
                      <label className="label">
                        {errors.businessStartingYear?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.businessStartingYear.message}
                          </span>
                        )}
                      </label>
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("studentSendingExperiance")}
                        className="input input-bordered input-sm w-full"
                      >
                        <option disabled selected>
                          How many students did you send abroad last year?
                        </option>
                        <option>0</option>
                        <option>1-50</option>
                        <option>50-200</option>
                        <option>200+</option>
                      </select>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("businesslicense")}
                        className="input input-bordered input-sm w-full"
                      >
                        <option disabled selected>
                          Is your business licensed by the government of your
                          country?
                        </option>

                        <option>Yes</option>
                        <option>No</option>
                        <option>Not Required in our country</option>
                      </select>
                    </div>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <input
                        type="number"
                        name="businessRegistraronNumber"
                        placeholder="Business registration number"
                        className="input input-bordered input-sm w-full"
                        {...register("businessRegistraronNumber")}
                      />
                    </div>
                    {/* single field */}

                    <p className="font-bold text-xl text-black text-center col-span-6 my-3">
                      COMPANY OPERATIONS
                    </p>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("counsellors")}
                        className="input input-bordered input-sm w-full"
                      >
                        <option disabled selected>
                          How many counsellors do you employ?
                        </option>
                        <option>1</option>
                        <option>1-5</option>
                        <option>5+</option>
                      </select>
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3">
                      <select
                        {...register("yesrExperience")}
                        className="input input-bordered input-sm w-full"
                      >
                        <option disabled selected>
                          On average how many years of relevant experience do
                          your counsellors have?
                        </option>

                        <option>1 Year</option>
                        <option>1-5 Year</option>
                        <option>5+ Year</option>
                      </select>
                    </div>
                    {/* single field */}
                    <p className="font-bold text-xl text-accent text-center col-span-6 my-3">
                      Loged in Agent or Student Details
                    </p>
                    {/* single field */}

                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <label className="block text-sm font-medium text-gray-700">
                        User Name
                      </label>
                      <input
                        type="text"
                        value={user?.displayName}
                        placeholder="Name"
                        className="input input-bordered input-sm w-full"
                        {...register("logedInUserName")}
                      />
                    </div>
                    {/* single field */}
                    <div className="col-span-6 sm:col-span-3 w-full  ">
                      <label className="block text-sm font-medium text-gray-700">
                        User Email
                      </label>
                      <input
                        type="email"
                        value={user.email}
                        placeholder="Email"
                        className="input input-bordered input-sm w-full"
                        {...register("email")}
                      />
                    </div>
                    {/* single field */}
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className=" w-full inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Send Application
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentRequestForm;
