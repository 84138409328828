import React from "react";
import FindAJobBanner from "../../components/FindAJob/FindAJobBanner";
import FindAJobForm from "../../components/FindAJob/FindAJobForm";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";
import EmploymentNav from "../../components/shared/header/EmploymentNav";

const FindAJob = () => {
  return (
    <>
      <EmploymentNav />
      <FindAJobBanner />
      <FindAJobForm />
      <EmploymentFooter />
    </>
  );
};

export default FindAJob;
