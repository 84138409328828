import React from "react";
import { Link } from "react-router-dom";

const AdmAboutDetails = () => {
  return (
    <div className="hero bg-base-100 py-10 drop-shadow-md">
      <div className="hero-content flex-col lg:flex-row">
        <img
          src="https://www.londonschoolofexcellence.com/wp-content/uploads/2021/06/immigration-lawyers-600x400.jpg"
          className="max-w-lg w-[100%] rounded shadow-xl"
          alt=""
        />
        <div className="lg:pl-10">
          <h1 className="text-2xl font-bold uppercase text-slate-700 text-start pb-1">
            London School of Excellence
          </h1>
          <p className="text-sm font-normal text-gray-600">
            The London School of Excellence (LSOE) is a prominent career guide
            recruitment agency in the UK that has been operating since 2013.
            Their goal is to develop a highly competent community, and they have
            achieved this through collaborations with various educational and
            cultural institutions. LSOE offers comprehensive and up-to-date
            information about study options in the UK and abroad, including
            application processes, visa requirements, accommodation options, and
            costs. They represent some of the most prestigious and affordable
            universities and colleges around the globe. At London School of
            Excellence (LSOE), we provide students and professionals with the
            best resources for exploring their study options both within the UK
            and internationally. Our team ensures that the information provided
            on our website is comprehensive and up-to-date, including details on
            application processes, visa requirements, accommodation options, and
            associated costs.
          </p>
          <br />
          <p className="text-sm font-normal text-gray-600">
            In today's world, virtual platforms have become increasingly
            important, and many educational institutions have adapted to this
            trend. LSOE is actively researching this transition and the
            potential of this new reality. To make the process as convenient as
            possible, we have designed our website to be accessible from the
            comfort of one's own home. Our website software allows students to
            easily fill out assessment forms, select university subjects, and
            submit necessary supporting documents.
          </p>
          <br />
          <Link to="/contact-london-school-of-excellence">
            <button className="bg-secondary hover:bg-sky-500 text-white font-bold py-2 px-4 border-b-4 border-info-700 rounded">
              Contact LSOE
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdmAboutDetails;
