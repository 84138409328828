import React from "react";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import SVABanner from "../../components/StudentVisaAdviceCompo/SVABanner";
import SVACompo from "../../components/StudentVisaAdviceCompo/SVACompo";

const StudentVisaAdvice = () => {
  return (
    <>
      <AdmissoinNav />
      <SVABanner />
      <SVACompo />
      <AdmissionFooter />
    </>
  );
};

export default StudentVisaAdvice;
