import React from "react";

const SriLankaBankContent = () => {
  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-5">
            <p className="text-lg font-normal">
              Are you planning to study or work in the UK? If yes, one of the
              most important requirements for obtaining a visa is to show proof
              of financial support from a UKBA approved bank or financial
              institution in Sri Lanka. This requirement ensures that you have
              sufficient funds to cover your living expenses while you're in the
              UK.
              <br />
              <br />
              <strong>
                Here's a comprehensive list of all the UKBA approved banks and
                financial institutions in Sri Lanka:
              </strong>
              <br />
              <ul className="list-inside list-decimal">
                <li>Amana Bank Ltd</li>
                <li>Axis Bank Ltd</li>
                <li>Bank of Ceylon</li>
                <li>Cargills Bank Ltd</li>
                <li>Citibank, N.A.</li>
                <li>Commercial Bank of Ceylon PLC</li>
                <li>Deutsche Bank AG</li>
                <li>DFCC Vardhana Bank PLC</li>
                <li>Habib Bank Ltd</li>
                <li>Hatton National Bank PLC</li>
                <li>ICICI Bank Ltd</li>
                <li>Indian Bank</li>
                <li>Indian Bank</li>
                <li>MCB Bank Ltd</li>
                <li>National Development Bank PLC</li>
                <li>Nations Trust Bank PLC</li>
                <li>Pan Asia Banking Corporation PLC</li>
                <li>People’s Bank</li>
                <li>Public Bank Berhad</li>
                <li>Sampath Bank PLC</li>
                <li>Seylan Bank PLC</li>
                <li>Standard Chartered Bank</li>
                <li>State Bank of India</li>
                <li>The Hongkong & Shanghai Banking Corporation Ltd</li>
                <li>Union Bank of Colombo PLC</li>
                <li>
                  Name of Financial Institution – Licensed Specialised Banks
                </li>
                <li>DFCC Bank</li>
                <li>Lankaputra Development Bank Ltd</li>
                <li>MBSL Savings Bank Ltd</li>
                <li>National Savings Bank</li>
                <li>Pradeshiya Sanwardhana Bank</li>
                <li>Sanasa Development Bank Ltd</li>
                <li>Sri Lanka Sarvings Bank Ltd</li>
                <li>State Mortgage & Investment Bank</li>
              </ul>
              <br />
              In conclusion, if you're planning to study or work in the UK, it's
              important to have financial support from a UKBA approved bank or
              financial institution in Sri Lanka. The banks and financial
              institutions mentioned above have been approved by the UKBA and
              offer a wide range of banking services to meet your needs.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SriLankaBankContent;
