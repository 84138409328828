import React from "react";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, NavLink } from "react-router-dom";
import auth from "../../../firebase.init";
import useAdmin from "../../../hooks/useAdmin";
import useAgent from "../../../hooks/useAgent";
import { ReactSVG } from "react-svg";
import applynowbtnstyle from "../../ApplyNowLeadCompo/ApplynowLead.module.css";

const GlobalNav = () => {
  const [user, loading, error] = useAuthState(auth);
  const [admin] = useAdmin(user);
  const [agent] = useAgent(user);
  const logout = () => {
    signOut(auth);
    localStorage.removeItem("accessToken");
  };
  let activeStyle = {
    backgroundColor: "#0FCFEC",
    fontweight: "bold",
    color: "#000000",
  };
  const menuItems = (
    <>
      <li>
        <NavLink
          className="font-medium justify-between"
          to="/university-admission"
        >
          Admission HUB
        </NavLink>
      </li>

      <div className="divider lg:divider-horizontal"></div>
      <li>
        <NavLink
          className="font-medium justify-between"
          to="/career-hub"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Career HUB
        </NavLink>
      </li>

      <div className="divider lg:divider-horizontal"></div>

      <li>
        <NavLink className={applynowbtnstyle.applynowleadbtn} to="/apply-now">
          Apply Now
        </NavLink>
      </li>

      {/* <div className="divider lg:divider-horizontal"></div> */}

      {user && admin && (
        <li>
          <NavLink to="/dashboard">Dashboard</NavLink>
        </li>
      )}
      {user && agent && (
        <li>
          <NavLink to="/agent-dashboard">Agent Portal</NavLink>
        </li>
      )}
    </>
  );
  return (
    <header className="sticky top-0 z-50">
      <div className="navbar bg-base-100 drop-shadow-md">
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex="0" className="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
            <ul
              tabIndex="0"
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              {menuItems}
            </ul>
          </div>
          <NavLink to="/" className="w-[14rem] lg:ml-10">
            <ReactSVG src="https://res.cloudinary.com/lsoe/image/upload/v1698255900/images/LSOE_Logo_lon5ab.svg" />
          </NavLink>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal p-0">{menuItems}</ul>
        </div>
        <div className="navbar-end">
          {user ? (
            <div className="dropdown dropdown-end">
              <label tabIndex={0}>
                <div className="avatar placeholder cursor-pointer">
                  <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
                    <span>{<img src={user.photoURL} alt="avatar" />}</span>
                  </div>
                </div>
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
              >
                <li>
                  <a href="/">Profile</a>
                </li>
                <li>
                  <a href="/">Setting</a>
                </li>
                <li>
                  <button className="btn btn-ghost" onClick={logout}>
                    Sgin Out
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <Link to="/login" className="btn">
              Get started
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default GlobalNav;
