import React, { useEffect } from "react";
import AdmissoinNav from "../../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../../components/shared/Footer/AdmissionFooter";
import BDBankCover from "../../../components/TempBlogCompo/BDBankCompo/BDBankCover";
import BDBankContent from "../../../components/TempBlogCompo/BDBankCompo/BDBankContent";

const BDBanks = () => {
  useEffect(() => {
    document.title =
      "UKBA Approved Banks & Financial Institutions in Bangladesh - London School of Excellence";
  }, []);
  return (
    <>
      <AdmissoinNav />
      <BDBankCover />
      <BDBankContent />
      <AdmissionFooter />
    </>
  );
};

export default BDBanks;
