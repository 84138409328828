import React from "react";

const NigeriaBankContent = () => {
  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-5">
            <p className="text-lg font-normal ">
              Are you a Nigerian resident applying for a visa to the United
              Kingdom and looking for a reliable financial institution to
              provide evidence of your financial support? This guide provides a
              comprehensive list of UKBA-approved banks and financial
              institutions in Nigeria.
              <br />
              <br />
              UK Visas and Immigration (UKVI) requires visa applicants to
              demonstrate that they have enough money to support themselves
              while in the UK. This can be in the form of bank statements or
              financial sponsorship letters. It is crucial to select a reputable
              and trustworthy bank or financial institution that meets the
              UKVI's requirements.
              <br />
              <br />
              <strong>
                Here are the top 10 UKBA-approved banks and financial
                institutions in Nigeria:
              </strong>
              <ul className="list-inside list-decimal">
                <li className="pb-3">
                  <strong>Access Bank:</strong> With over 600 branches in
                  Nigeria and a presence in several other African countries,
                  Access Bank is a reliable financial institution that meets the
                  UKVI's requirements.
                </li>
                <li className="pb-3">
                  <strong>First Bank of Nigeria: </strong> Established in 1894,
                  First Bank of Nigeria is one of the oldest banks in the
                  country and is a trusted name in the banking industry.
                </li>
                <li className="pb-3">
                  <strong>Guaranty Trust Bank: </strong> Known for its
                  innovation and customer-centric approach, Guaranty Trust Bank
                  is one of Nigeria's leading financial institutions.
                </li>
                <li className="pb-3">
                  <strong>Zenith Bank: </strong> Zenith Bank has a strong
                  reputation for excellent customer service and financial
                  stability, making it a reliable option for visa applicants.
                </li>
                <li className="pb-3">
                  <strong>United Bank for Africa (UBA): </strong> With a
                  presence in over 20 African countries, UBA is one of Nigeria's
                  largest financial institutions and meets the UKVI's
                  requirements.
                </li>
                <li className="pb-3">
                  <strong>Stanbic IBTC Bank: </strong> Stanbic IBTC Bank is a
                  subsidiary of South Africa's Standard Bank Group and is known
                  for its innovative financial solutions.
                </li>
                <li className="pb-3">
                  <strong>Ecobank Nigeria: </strong> Ecobank Nigeria is part of
                  the Ecobank Group, which operates in over 30 African countries
                  and has a strong reputation for customer service.
                </li>
                <li className="pb-3">
                  <strong>Fidelity Bank Nigeria: </strong> Fidelity Bank Nigeria
                  is a full-service bank with a focus on providing personalized
                  banking solutions to its customers.
                </li>
                <li className="pb-3">
                  <strong>Diamond Bank: </strong> Diamond Bank is a reputable
                  financial institution with a presence in several African
                  countries and a strong focus on customer satisfaction.
                </li>
                <li className="pb-3">
                  <strong>Union Bank of Nigeria: </strong> With over 300
                  branches in Nigeria, Union Bank of Nigeria is a reliable
                  option for visa applicants looking for a UKBA-approved bank.
                </li>
              </ul>
              <br />
              When selecting a bank or financial institution to provide evidence
              of financial support, it is essential to ensure that the bank
              statement meets the UKVI's requirements. The statement should
              include the applicant's name, account number, and balance, and
              should be dated within 28 days of the visa application.
              <br />
              <br />
              In conclusion, selecting a reliable and trustworthy bank or
              financial institution is crucial when applying for a UK visa. The
              above-listed UKBA-approved banks and financial institutions in
              Nigeria are excellent options for Nigerian residents looking for a
              reliable financial institution to provide evidence of their
              financial support.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NigeriaBankContent;
