import React from "react";
import { Link } from "react-router-dom";

const AgentReq = ({ agentReq }) => {
  const {
    _id,
    businessName,
    directPhoneNumber,
    companyEmail,
    firstName,
    lastName,

    country,
  } = agentReq;
  return (
    <tr>
      <th>1</th>
      <td>
        <span className="badge badge-ghost badge-sm">{_id}</span>
      </td>
      <td>
        <div className="flex items-center space-x-3">
          <div>
            <div className="font-bold">{businessName}</div>
            <div className="text-sm opacity-50">
              {firstName} {lastName}
            </div>
          </div>
        </div>
      </td>
      <td>
        <span className="badge badge-ghost badge-sm">{directPhoneNumber}</span>
      </td>
      <td>
        <span className="badge badge-ghost badge-sm">{companyEmail}</span>
      </td>
      <td>
        <span className="badge badge-ghost badge-sm">{country}</span>
      </td>

      <th>
        <Link to={`/agent-request/${_id}`} className="btn btn-secondary btn-xs">
          details
        </Link>
      </th>
    </tr>
  );
};

export default AgentReq;
