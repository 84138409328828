import React from "react";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import DetailsStudentsAcco from "../../components/StudentAccommodationCompo/DetailsStudentsAcco";
import StAcoBanner from "../../components/StudentAccommodationCompo/StAcoBanner";

const StudentAccommodation = () => {
  return (
    <>
      <AdmissoinNav />
      <StAcoBanner />
      <DetailsStudentsAcco />
      <AdmissionFooter />
    </>
  );
};

export default StudentAccommodation;
