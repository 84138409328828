import React from "react";

const RecruitmentProcessDetails = () => {
  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-10">
            <p className="text-sm font-normal">
              Effective hiring is essential, especially when the labor market is
              tight. It guarantees that the workforce possesses the necessary
              knowledge, expertise, and skills to meet the organization's
              present and future demands. By using workforce planning data to
              determine the capabilities required for organizational
              performance, effective resourcing impacts the long-term success of
              the firm rather than just filling a short-term vacancy. The labor
              market developments that have an impact on hiring and resourcing
              must also be understood.
            </p>
            <br />
            <p className="text-sm font-normal">
              All of the aforementioned facts were acknowledged by The London
              Source of Employment (LSOE).
            </p>
            <br />
            <p className="text-sm font-normal">
              <strong className="text-primary">
                The following are the steps in the hiring process:
              </strong>
            </p>
            <ul>
              <li className="list-disc list-inside">Role definition</li>
              <li className="list-disc list-inside">Recruiting candidates</li>
              <li className="list-disc list-inside">
                Controlling the application and hiring procedure
              </li>
              <li className="list-disc list-inside">
                Scheduling an appointment
              </li>
            </ul>
            <br />
            <p className="text-sm font-normal">
              The London Source of Employment Services (LSOE) employs a
              systematic approach in recruitment. We serve the requirements of
              numerous industries, including health care, construction,
              hospitality, engineering, medical services, oil & gas shipping,
              and so on, as an international recruitment agency.
            </p>
            <br />
            <p className="text-sm font-normal">
              A letter of authorization, authorizing sources to serve as their
              recruitment consultants in the UK is typically issued by
              international businesses along with a demand letter outlining
              employment openings. Also, to handle visa and other processes, a
              draft of the employment contract between the employer and
              employees is also sent by the foreign company. The London Source
              Of Employment (LSOE)Services then starts the procedure of
              soliciting applications from applicants, shortlisting, and finally
              calling chosen applicants for tests and oral interviews either by
              the employer or his authorized representative at suitable places
              in the UK. The HR recruitment firm, LSOE Recruitment Services,
              offers logistical help.
            </p>
            <br />
            <p className="text-sm font-normal">
              As an international placement agency, The London Source Of
              Employment (LSOE) is occasionally given responsibility by
              companies abroad. We follow a set procedure to guarantee that only
              the ideal candidate is chosen who matches the job profile. At The
              London Source of Employment (LSOE) Services, HR executives work
              together with industry experts in each subject to conduct tests
              and interviews. The LSOE Recruitment Services, a staffing agency,
              also organizes the medical examinations of chosen candidates at
              accredited facilities. Following this, the immigration and
              ticketing processes are started, and then the visa application
              process is started with the embassy of the employer's nation.
              Prior to leaving the UK, the LSOE Recruitment Services also offers
              basic orientation training to selected candidates, educating them
              on the labour laws of the destination country and the working
              environment.
            </p>

            <img
              src="https://res.cloudinary.com/lsoe/image/upload/v1675365806/images/LSOE_RECRUITMENT_SERVICES_xc0alp.png"
              className="w-[100%] h-[200px] object-cover my-10"
              alt=""
            />

            <p className="text-sm font-normal">
              <strong className="text-primary">Office Network:</strong> <br />
              Our extensive network of offices, which are scattered across all
              of the major cities in the nation, enables us to easily access the
              greatest people. Our associate at of Foreign Employment is located
              at while our head office is in London, UK. We also have
              international partners in the EU's neighboring nations. Our
              extensive network enables us to reach the broadest audience and
              satisfy every organization's recruitment needs.{" "}
            </p>
            <br />
            <p className="text-sm font-normal">
              <strong className="text-primary">Our Work Process:</strong> <br />
              We continually search the market for outstanding applicants.
              Following the selection of the candidates, we speak with each one
              in-depth to get specific information (on topics such as
              educational background, experience, job history, career goals,
              personality traits, compensation etc.){" "}
            </p>
            <br />
            <p className="text-sm font-normal">
              <strong className="text-primary">
                Our Team & Infrastructure:
              </strong>{" "}
              <br />
              We have a group of seasoned, skilled recruiters, as well as
              strong, integrated recruitment technologies. We have all the most
              recent amenities for communication, frequent transportation, etc.,
              which speeds up the entire procedure. All of our recruiters have
              extensive managerial experience as well. They have "been there and
              done that" for the majority of the positions. They fully
              comprehend the job's requirements and recognize that an
              organization's success depends on its human resources.
            </p>
            <br />
            <p className="text-sm font-normal">
              <strong className="text-primary">Our Training Facility: </strong>{" "}
              <br />
              Our main objective is to provide short-term courses (CPD) for job
              searchers and to educate human resources in order to develop
              employment chances. This course's major goal is to provide job
              searchers with the necessary skills so they can enter the
              competitive job market with ease.
              <br />
              We have acknowledged that the biggest barrier for job seekers can
              be maintaining good communication, a sufficient level of English,
              and fundamental office work skills.
              <br />
              Therefore, the committed team members of our two companies, London
              School of Excellence and London Source of Employment (LSOE), are
              created to build qualified workforce in an innovative way. Our
              motto is LEARN- TEACH- LEAD.{" "}
            </p>
            <br />
            <p className="text-sm font-normal">
              <strong className="text-primary">Our HR Management: </strong>{" "}
              <br />
              The most widely used HR software is what we are utilizing. We must
              quickly determine who is absent due to illness and who is late, as
              well as establish employee regulations, using a laptop or mobile
              app. The employee vacation schedule is also current. The
              technology made performing the same chores quicker and simpler.
            </p>
            <br />
            <p className="text-sm font-normal">
              <strong className="text-primary">Required Documents: </strong>{" "}
              <br />
              The requirement of documents usually varies from country to
              country but principally these types of documents are essential
              such as;
              <br />
              <li className="list-disc list-inside">Demand Letter</li>
              <li className="list-disc list-inside">Power of Attorney</li>
              <li className="list-disc list-inside">Service Contract</li>
              <li className="list-disc list-inside">Employment Contract</li>
              <li className="list-disc list-inside">Guarantee Letter</li>
              To be able to obtain the approval letter from the Government of
              Bangladesh. The following are, in principle, required for the
              government formalities and procedures;
            </p>
            <p className="text-sm font-normal">
              <strong className="text-primary">Demand Letter: </strong> <br />
              Addressed to The London Source of Employment (LSOE), London, UK,
              this letter outlines the number of workers required in each
              category, as well as their compensation, work schedules, lunches,
              and perks.
            </p>
            <p className="text-sm font-normal">
              <strong className="text-primary">Power of Attorney: </strong>{" "}
              <br />
              This letter is intended to appoint The London Source of Employment
              (LSOE), London, UK, as an authorized representative of the UK.{" "}
            </p>
            <p className="text-sm font-normal">
              <strong className="text-primary">Service Contract: </strong>{" "}
              <br />
              The service contract between the hiring employer and a UK
              recruitment agency should be made available to us.{" "}
            </p>
            <p className="text-sm font-normal">
              <strong className="text-primary">Employment Contract: </strong>{" "}
              <br />
              One copy that the employer and employee have both signed and
              sealed.{" "}
            </p>
            <p className="text-sm font-normal">
              <strong className="text-primary">The guarantee Letter: </strong>{" "}
              <br />
              The employer company should state on a signed and stamped copy
              that it will not send people abroad for employment.
              <br />
              <br />
              For the Kingdom of Saudi Arabia
              <br />
              <br />
              Following additional Documents are essential for KSA only.
              <br />
              <br />
              Authorization Letter/Counselor Letter, Company Registration (CR)
              Copy, Visa Slip (Bank Slip)
              <br />
              <br />
              Note: Documents should be duly sealed by the Ministry of Foreign
              Affairs, Chamber Of Commerce or concerned Government Authority &
              Embassy of the respective country.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecruitmentProcessDetails;
