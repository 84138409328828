import React from "react";
import { Link } from "react-router-dom";

const ApplicationFormBanner = () => {
  return (
    <div
      className="hero border-b-4 border-indigo-500"
      style={{
        backgroundImage: `url("https://res.cloudinary.com/lsoe/image/upload/v1680897970/images/4_osi8py.jpg")`,
      }}
    >
      <div className="hero bg-opacity-60"></div>
      <div className="hero-content flex-col text-center text-neutral-content p-10 lg:py-20">
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-200 sm:text-5xl sm:leading-none md:text-6xl">
          New International Application
        </h2>
        <div className="text-md text-white mt-3 breadcrumbs">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/university-admission">Admission HUB</Link>
            </li>
            <li>Apply For Assessment</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ApplicationFormBanner;
