import React from "react";
import EmAboutDetails from "../../components/EmploymentAboutCompo/EmAboutDetails";
import EmploymentAboutBanner from "../../components/EmploymentAboutCompo/EmploymentAboutBanner";
import MissionVissionEmpHub from "../../components/EmploymentAboutCompo/MissionVissionEmpHub";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";
import EmploymentNav from "../../components/shared/header/EmploymentNav";

const AboutEmploymentHub = () => {
  return (
    <>
      <EmploymentNav />
      <EmploymentAboutBanner />
      <EmAboutDetails />
      <MissionVissionEmpHub />
      <EmploymentFooter />
    </>
  );
};

export default AboutEmploymentHub;
