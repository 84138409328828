import React from "react";
import { toast } from "react-toastify";

const UserRow = ({ user }) => {
  const { _id, email, role } = user;

  const makeAdmin = () => {
    fetch(
      `https://lsoe-backend-1392ffa8977e.herokuapp.com/user/admin/${email}`,
      {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ role: "admin" }),
      }
    )
      .then((res) => {
        if (res.status === 403) {
          toast.error("Failed to Make an admin");
        }
        return res.json();
      })
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success(`Successfully made an admin`);
        }
      });
  };
  const makeAgent = () => {
    fetch(
      `https://lsoe-backend-1392ffa8977e.herokuapp.com/user/agent/${email}`,
      {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ role: "agent" }),
      }
    )
      .then((res) => {
        if (res.status === 403) {
          toast.error("Failed to Make an agent");
        }
        return res.json();
      })
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success(`Successfully made an agent`);
        }
      });
  };
  return (
    <tr>
      <th>1</th>
      <td>
        <span className="badge badge-ghost badge-sm">{_id}</span>
      </td>
      <td>{email}</td>
      {/* <td>
        <div className="flex items-center space-x-3">
          <div>
            <div className="font-bold">asdf</div>
            <div className="text-sm opacity-50">United States</div>
          </div>
        </div>
      </td> */}
      <td>
        {role !== "admin" ? (
          <button
            onClick={makeAdmin}
            className="btn text-black btn-secondary btn-xs"
          >
            Make Admin
          </button>
        ) : (
          <button disabled className="btn text-black btn-xs">
            Already Admin
          </button>
        )}
      </td>
      <td>
        {role !== "agent" ? (
          <button
            onClick={makeAgent}
            className="btn btn-primary text-black btn-xs"
          >
            Make Agent
          </button>
        ) : (
          <button disabled className="btn text-black btn-xs">
            Already Agent
          </button>
        )}
      </td>
      <td>
        <button className="btn btn-error btn-xs">Remove User</button>
      </td>

      {/* <th>
        <button className="btn btn-ghost btn-xs">details</button>
      </th> */}
    </tr>
  );
};

export default UserRow;
