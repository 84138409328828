import React from "react";
import { Link } from "react-router-dom";

const FinanceWUA = () => {
  return (
    <div className="hero bg-base-100 py-10">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <img
          src="https://res.cloudinary.com/lsoe/image/upload/v1678983899/Finance_b3wpjq.png"
          alt=""
          className="max-w-lg w-[100%]"
        />
        <div className="lg:pr-10">
          <h1 className="text-2xl font-bold uppercase text-slate-700 text-start pb-1">
            Finance
          </h1>
          <p className="text-sm font-normal text-gray-600">
            Living in a new country can be financially difficult. LSOE’s guides
            can help you get an idea on cost of living in the country you are
            moving to.
          </p>
          <br />
          <p className="text-sm font-normal text-gray-600">
            You can check out our finances guide or consult an expert for better
            insights.
          </p>
          <br />

          <Link to="/student-financial-information">
            <button className="bg-secondary hover:bg-sky-500 text-white font-bold py-2 px-4 border-b-4 border-info-700 rounded">
              Finance Information
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FinanceWUA;
