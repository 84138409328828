import React from "react";
import ComingSoon from "../../components/shared/ComingSoon";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";

const EnglishSchool = () => {
  return (
    <>
      <AdmissoinNav />
      <ComingSoon />
      <AdmissionFooter />
    </>
  );
};

export default EnglishSchool;
