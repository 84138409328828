import React from "react";

const BlockquoteSFI = () => {
  return (
    <div className="my-10 mx-10">
      <blockquote className="p-4 my-4 border-l-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">
        <p className="text-md italic font-medium leading-relaxed text-gray-900 dark:text-white">
          "YOU WILL NEED TO PROVIDE SCANS OF THE FINANCIAL EVIDENCE YOU PLAN TO
          USE TO THE VISA COMPLIANCE OFFICE AT THE UNIVERSITY BEFORE YOUR CAS
          CAN BE ISSUED. THIS IS SO THAT THE UNIVERSITY CAN CHECK THAT, TO THE
          BEST OF THEIR KNOWLEDGE; YOUR FINANCIAL EVIDENCE SHOWS THE CORRECT
          FUNDS FOR THE 28-DAY PERIOD AND IS IN THE CORRECT FORMAT."
        </p>
      </blockquote>
    </div>
  );
};

export default BlockquoteSFI;
