import React from "react";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import ApplyNowCover from "../../components/ApplyNowLeadCompo/ApplyNowCover";
import ApplyNowCard from "../../components/ApplyNowLeadCompo/ApplyNowCard/ApplyNowCard";
import JotFormCompo from "../../components/ApplyNowLeadCompo/JotFormCompo";

const ApplyNow = () => {
  return (
    <div>
      <AdmissoinNav />
      {/* <ApplyNowCover /> */}
      {/* <ApplyNowCard /> */}
      <JotFormCompo />
      {/* <AdmissionFooter /> */}
    </div>
  );
};

export default ApplyNow;
