import React from "react";
import ModernSlaveryPolicyBanner from "../../components/ModernSlaveryPolicyCompo.js/ModernSlaveryPolicyBanner";
import SlaveryPolicy from "../../components/PrivacyPpolicyCompo/SlaveryPolicy";
import GlobalNav from "../../components/shared/header/GlobalNav";
import GlobalFooter from "../../components/shared/Footer/GlobalFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";

const ModernSlaveryPolicy = () => {
  return (
    <>
      <AdmissoinNav />
      <ModernSlaveryPolicyBanner />
      <SlaveryPolicy />
      <AdmissionFooter />
    </>
  );
};

export default ModernSlaveryPolicy;
