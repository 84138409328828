import React from "react";
import { Link } from "react-router-dom";

const AgentCards = () => {
  return (
    <div>
      <div className="mt-10 w-full flex justify-between px-4 md:px-6 xl:px-8">
        <div className="flex flex-col w-full lg:flex-row">
          <div className="grid flex-grow card bg-base-300 rounded-box place-items-center">
            <div className="text-2xl font-bold text-slate-700 text-start pb-1">
              Agent Dashboard
            </div>
          </div>
          <div className="divider lg:divider-horizontal text-secondary" />

          <div className="grid flex-grow card bg-base-300 rounded-box place-items-center">
            <Link to="/local-file-assessment">
              <button className="btn btn-secondary capitalize text-white font-bold bg-gradient-to-r from-secondary to-pink-700 border-0">
                New UK Local Application
              </button>
            </Link>
          </div>
          <div className="divider lg:divider-horizontal text-secondary" />
          <div className="grid flex-grow card bg-base-300 rounded-box place-items-center">
            <Link to="/apply-now-for-assessment">
              <button className="btn btn-secondary capitalize text-white font-bold bg-gradient-to-r from-secondary to-pink-700 border-0">
                New International Application
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 p-4 md:p-6 xl:p-8 my-6 ">
        {/* card one Total applications */}

        <div className="bg-[#0469D1] shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-100">
                00
              </span>
              <h3 className="text-base font-normal text-gray-100">
                All Applications
              </h3>
            </div>
          </div>
        </div>
        <div className="bg-[#F3D600] shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-100">
                00
              </span>
              <h3 className="text-base font-normal text-gray-100">
                Drafts Applications
              </h3>
            </div>
          </div>
        </div>
        <div className="bg-[#FE9402] shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-100">
                00
              </span>
              <h3 className="text-base font-normal text-gray-100">
                Submitted Applications
              </h3>
            </div>
          </div>
        </div>

        <div className="bg-[#7C03CE] shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-100">
                00
              </span>
              <h3 className="text-base font-normal text-gray-100">
                Assessment
              </h3>
            </div>
          </div>
        </div>

        <div className="bg-[#05B60F] shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-100">
                00
              </span>
              <h3 className="text-base font-normal text-gray-100">Enrolled</h3>
            </div>
          </div>
        </div>

        <div className="bg-red-600 shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-100">
                00
              </span>
              <h3 className="text-base font-normal text-gray-100">Rejected</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentCards;
