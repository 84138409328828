import React from "react";
import ComingSoon from "../../components/shared/ComingSoon";

const BecameATeacher = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default BecameATeacher;
