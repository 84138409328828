import React from "react";
import AdmissionContactBanner from "../../components/AdmissionContactCompo/AdmissionContactBanner";
import AdmissionContactCard from "../../components/AdmissionContactCompo/AdmissionContactCard";
import ContactForm from "../../components/AdmissionContactCompo/ContactForm";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import AdmissionContacOfficeImage from "../../components/AdmissionContactCompo/AdmissionContacOfficeImage";

const ContactAdmissionHub = () => {
  return (
    <div>
      <AdmissoinNav />
      <AdmissionContactBanner />
      <AdmissionContactCard />
      <ContactForm />
      <AdmissionContacOfficeImage />
      <AdmissionFooter />
    </div>
  );
};

export default ContactAdmissionHub;
