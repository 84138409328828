import React from "react";
import PrivacyPolicyBanner from "../../components/PrivacyPpolicyCompo/PrivacyPolicyBanner";
import PrivacyPolicyDetails from "../../components/PrivacyPpolicyCompo/PrivacyPolicyDetails";
import GlobalNav from "../../components/shared/header/GlobalNav";
import GlobalFooter from "../../components/shared/Footer/GlobalFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";

const PrivacyPolicy = () => {
  return (
    <div>
      <AdmissoinNav />
      <PrivacyPolicyBanner />
      <PrivacyPolicyDetails />
      <AdmissionFooter />
    </div>
  );
};

export default PrivacyPolicy;
