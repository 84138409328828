import React from "react";

const ChatModal = () => {
  return (
    <>
      <div className="chat chat-start">
        <div className="chat-image avatar">
          <div className="w-10 rounded-full">
            <img src="https://ui-avatars.com/api/?name=LSOE" />
          </div>
        </div>
        <div className="chat-header">
          Obi-Wan Kenobi
          <time className="text-xs opacity-50">12:45</time>
        </div>
        <div className="chat-bubble">Please update IELTS Documents!</div>
      </div>
      <div className="chat chat-end">
        <div className="chat-image avatar">
          <div className="w-10 rounded-full">
            <img src="https://ui-avatars.com/api/?name=Agent" />
          </div>
        </div>
        <div className="chat-header">
          Anakin
          <time className="text-xs opacity-50">12:46</time>
        </div>
        <div className="chat-bubble">Updated</div>
      </div>
      {/* chat status input */}
      <div className="flex justify-center mt-10">
        <div className="form-control">
          <div className="input-group">
            <input
              type="text"
              placeholder="Write Something..."
              className="input input-bordered"
            />
            <button className="btn">Update Chat</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatModal;
