import React from "react";
import AdminCards from "../../components/DashboardCompo/AdminDashboardHeader/AdminCards";
import DashboardFooter from "../../components/DashboardCompo/AdminDashboardHeader/DashboardFooter";

const IndexAdminDashboard = () => {
  return (
    <div>
      <AdminCards />
      <DashboardFooter />
    </div>
  );
};

export default IndexAdminDashboard;
