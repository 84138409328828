import React from "react";
import ComingSoon from "../../components/shared/ComingSoon";

const StartLearning = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default StartLearning;
