import React from "react";
import ContactForm from "../../components/AdmissionContactCompo/ContactForm";
import EmploymentContactBanner from "../../components/EmploymentContactCompo/EmploymentContactBanner";
import EmploymentContactInfo from "../../components/EmploymentContactCompo/EmploymentContactInfo";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";
import EmploymentNav from "../../components/shared/header/EmploymentNav";

const ContactEmploymentHub = () => {
  return (
    <div>
      <EmploymentNav />
      <EmploymentContactBanner />
      <EmploymentContactInfo />
      <ContactForm />

      <EmploymentFooter />
    </div>
  );
};

export default ContactEmploymentHub;
