import React, { useEffect } from "react";
import CalendlyBooking from "../../components/CareerHubCompo/CalendlyBooking";
import CareerHubCompoBanner from "../../components/CareerHubCompo/CareerHubCompoBanner";
import GlobalNav from "../../components/shared/header/GlobalNav";
import GlobalFooter from "../../components/shared/Footer/GlobalFooter";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";

const CareerHub = () => {
  useEffect(() => {
    document.title = "Career Hub - LSOE";
  }, []);
  return (
    <>
      <AdmissoinNav />
      <CareerHubCompoBanner />
      <CalendlyBooking />
      <AdmissionFooter />
    </>
  );
};

export default CareerHub;
