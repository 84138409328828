import React from "react";
import EmploymentNav from "../../components/shared/header/EmploymentNav";
import RecruitmentProcessBanner from "../../components/RecruitmentProcessCompo/RecruitmentProcessBanner";
import RecruitmentProcessDetails from "../../components/RecruitmentProcessCompo/RecruitmentProcessDetails";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";

const RecruitmentProcess = () => {
  return (
    <>
      <EmploymentNav />
      <RecruitmentProcessBanner />
      <RecruitmentProcessDetails />
      <EmploymentFooter />
    </>
  );
};

export default RecruitmentProcess;
