import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import AdminDashboardHeader from "../../components/DashboardCompo/AdminDashboardHeader/AdminDashboardHeader";
import { useAuthState } from "react-firebase-hooks/auth";
import useAdmin from "../../hooks/useAdmin";
import useAgent from "../../hooks/useAgent";
import auth from "../../firebase.init";

const DashboardAgent = () => {
  const [user] = useAuthState(auth);
  const [admin] = useAdmin(user);
  const [agent] = useAgent(user);
  return (
    <div>
      <AdminDashboardHeader />
      <div className="drawer lg:drawer-open">
        <input
          id="admin-dashboard-sidebar"
          type="checkbox"
          className="drawer-toggle"
        />
        <div className="drawer-content">
          {/* Page content here  */}
          <Outlet />
        </div>
        <div className="drawer-side">
          <label
            htmlFor="admin-dashboard-sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="menu p-4 w-80 min-h-full bg-base-100 text-base-content">
            {/* Sidebar content here */}
            <li>
              <NavLink to="/agent-dashboard/agent-overview">
                <svg
                  className="w-6 h-6 text-gray-500 group-hover:text-gray-900 transition duration-75"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span>Overview</span>
              </NavLink>
            </li>

            <li>
              <details>
                <summary>
                  <NavLink to="/agent-dashboard/all-applications">
                    All Applications
                  </NavLink>
                </summary>
                <ul className="p-2 bg-base-200">
                  <li>
                    <a>Drafts Applications</a>
                  </li>
                  <li>
                    <a>Submitted Applications</a>
                  </li>
                  <li>
                    <a>On Assessment</a>
                  </li>
                  <li>
                    <a>Enrolled</a>
                  </li>
                  <li>
                    <a>Rejected</a>
                  </li>
                </ul>
              </details>
            </li>

            <li>
              <NavLink to="/agent-dashboard/drafts-applications">
                Drafts Applications
              </NavLink>
            </li>
            <li>
              <NavLink to="/agent-dashboard/submitted-applications">
                Submitted Applications
              </NavLink>
            </li>
            <li>
              <NavLink to="/agent-dashboard/assessment-applications">
                Assessment
              </NavLink>
            </li>
            <li>
              <NavLink to="/agent-dashboard/enrolled-applications">
                Enrolled
              </NavLink>
            </li>
            <li>
              <NavLink to="/agent-dashboard/rejected-applications">
                Rejected
              </NavLink>
            </li>
            <li>
              <NavLink to="/">Back to Home</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DashboardAgent;
