import React from "react";

const ApplyAssesmentJotForm = () => {
  return (
    <iframe
      id="JotFormIFrame-233622336891459"
      title="Apply for Assessment"
      allowTransparency="true"
      allowFullScreen="true"
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/233622336891459"
      frameBorder={0}
      style={{
        minWidth: "100%",
        maxWidth: "100%",
        height: "100vh",
        border: "none",
      }}
    ></iframe>
  );
};

export default ApplyAssesmentJotForm;
