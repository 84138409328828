import React from "react";
import AdmissoinNav from "../../components/shared/header/AdmissoinNav";
import AdmissionFooter from "../../components/shared/Footer/AdmissionFooter";
import AgentRequestBanner from "../../components/ApplyNowForAgentCompo/AgentRequestBanner";
import AgentRequestForm from "../../components/ApplyNowForAgentCompo/AgentRequestForm";

const ApplyNowForAgent = () => {
  return (
    <>
      <AdmissoinNav />
      <AgentRequestBanner />
      <AgentRequestForm />
      <AdmissionFooter />
    </>
  );
};

export default ApplyNowForAgent;
