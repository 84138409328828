import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ChatModal from "./chat/ChatModal";

const ManageSingleApplication = () => {
  const [stDetails, setStDetails] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetch(
      `https://lsoe-backend-1392ffa8977e.herokuapp.com/application/${id}?timestamp=${Date.now()}`
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((json) => setStDetails(json))
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  return (
    <div className="mockup-window border">
      <div className="px-4 py-5 bg-base-200">
        <div>
          <Link
            to="/dashboard/manage-applications"
            className="btn btn-secondary"
          >
            Back To Dashboard
          </Link>
        </div>
        <div>{stDetails._id}</div>
        {stDetails.body ? (
          <div>
            <h1>Title: {stDetails.body.title}</h1>
            <h1>
              Full Name: {stDetails.body.firstName} {stDetails.body.lastName}
            </h1>
            <div>
              <button
                className="btn btn-primary text-black btn-xs"
                onClick={() => window.my_modal_5.showModal()}
              >
                Open Chat
              </button>
              <dialog
                id="my_modal_5"
                className="modal modal-bottom sm:modal-middle"
              >
                <div className="modal-box">
                  <h3 className="font-bold text-lg">Hello!</h3>
                  <p className="py-4">
                    <ChatModal />
                  </p>
                  <div className="modal-action">
                    <form method="dialog">
                      {/* if there is a button in form, it will close the modal */}
                      <button className="btn">Close</button>
                    </form>
                  </div>
                </div>
              </dialog>
            </div>
            <div>
              <select className="select select-bordered select-xs w-full max-w-xs">
                <option disabled selected>
                  Change status
                </option>
                <option>Drafts</option>
                <option>Submitted</option>
                <option>On Assessment</option>
                <option>Enrolled</option>
                <option>Rejected</option>
              </select>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default ManageSingleApplication;
