import React from "react";
import { Link } from "react-router-dom";

const Application = ({ application }) => {
  const { _id } = application;
  const { firstName, lastName, email, logedInUserName, semester } =
    application.body;

  return (
    <tr>
      <th>1</th>
      <td>
        <span className="badge badge-ghost badge-sm">{_id}</span>
      </td>
      <td>
        <div className="flex items-center space-x-3">
          <div>
            <div className="font-bold">
              {firstName} {lastName}
            </div>
            <div className="text-sm opacity-50">
              {" "}
              <span className="font-bold">Agent: </span> {logedInUserName}
            </div>
          </div>
        </div>
      </td>
      <td>Received</td>
      <td>{semester}</td>
      <td>{email}</td>
      <th>
        <Link to={`/application/${_id}`} className="btn btn-secondary btn-xs">
          details
        </Link>
      </th>
    </tr>
  );
};

export default Application;
